import React from 'react';
import Icon from './Icon';
import { ErrorPhrase } from './ErrorHandle';
import { PaymentStatus } from '../utils/axiosRetry';

export const ERRORS = {
  REVIEW: ['SPAR002', 'SPAR006', 'SPAR007'],
  OTHER_METHODS: ['SPAR001', 'SPAR008', 'SPAR003', 'SPAR004', 'SPAR005', 'SPAR009'],
  FINAL: ['SPAN010', 'SPAN011']
};

export function errorType(error_array, errors) {
  if (errors) {
    return error_array.includes(errors[0]);
  }
}

function setModalErrorValues(setFieldValue, values) {
  setFieldValue('paymentRequest', { ...values.paymentRequest, status: PaymentStatus.not_started, errors: null });
}

class RetryModal extends React.Component {
  toggleModal = (event) => {
    if (event.target.closest('.modal') === null) {
      setModalErrorValues(this.props.setFieldValue, this.props.values);
    }
  };
  modalTitle(text) {
    if (this.props.errors) {
      const isTitle = text === 'title';
      if (errorType(ERRORS.REVIEW, this.props.errors)) {
        return isTitle
          ? 'eita 🥺 aconteceu um problema no pagamento'
          : 'confira se os dados do cartão estão corretos ou tente outro método de pagamento';
      } else if (errorType(ERRORS.OTHER_METHODS, this.props.errors)) {
        return isTitle
          ? 'ops 🥺  não conseguimos finalizar a compra'
          : 'você pode tentar com outro cartão ou pagar em boleto';
      } else if (errorType(ERRORS.FINAL, this.props.errors)) {
        return isTitle ? 'ops! aconteceu um problema no pagamento' : null;
      }
    }
  }

  render() {
    return !errorType(ERRORS.FINAL, this.props.errors) ? (
      <div
        onClick={(event) => {
          this.toggleModal(event);
        }}
        className={`modal-background ${this.props.show ? 'modal-show' : 'modal-hide'}`}
      >
        <div id="payment-retry-modal" className="modal">
          <Text text={this.modalTitle('title')} styleName="modal-title" />
          <Text text={this.modalTitle('subtitle')} styleName="modal-text" />
          <PrimaryBtn errors={this.props.errors} values={this.props.values} setFieldValue={this.props.setFieldValue} />
          <SecundaryBtn
            image="card"
            btnAction="usar outro cartão"
            values={this.props.values}
            setFieldValue={this.props.setFieldValue}
          />
          <SecundaryBtn
            image="barcode"
            btnAction="gerar boleto"
            values={this.props.values}
            setFieldValue={this.props.setFieldValue}
          />
        </div>
      </div>
    ) : (
      <ErrorPage />
    );
  }
}

export default RetryModal;

const ErrorPage = () => {
  document.querySelector('.dados').style.display = 'none';
  return (
    <div className="error-container">
      <ErrorPhrase />
    </div>
  );
};

class SecundaryBtn extends React.Component {
  btnAction(values, setFieldValue) {
    if (this.props.image === 'barcode') {
      setFieldValue('payment_chosen', 'boleto');
      setModalErrorValues(setFieldValue, values);
    } else if (this.props.image === 'card') {
      setFieldValue('card_birthday', '');
      setFieldValue('card_cvv', '');
      setFieldValue('card_document', '');
      setFieldValue('card_expirationMonth', '');
      setFieldValue('card_expirationYear', '');
      setFieldValue('card_name', '');
      setFieldValue('card_number', '');
      setFieldValue('card_phone', '');
      setModalErrorValues(setFieldValue, values);
    }
  }

  render() {
    return (
      <button
        className="secondary-btn"
        onClick={() => {
          this.btnAction(this.props.values, this.props.setFieldValue);
        }}
      >
        <Icon name={this.props.image} /> {this.props.btnAction}
      </button>
    );
  }
}

class PrimaryBtn extends React.Component {
  validateFields(values, setFieldValue) {
    setFieldValue('fixFields', 'ops! revise os campos obrigatórios ;)');
    setModalErrorValues(setFieldValue, values);
  }

  render() {
    return errorType(ERRORS.REVIEW, this.props.errors) ? (
      <button className="primary-btn" onClick={() => this.validateFields(this.props.values, this.props.setFieldValue)}>
        {' '}
        voltar e revisar
      </button>
    ) : null;
  }
}

const Text = ({ text, styleName }) => {
  return text ? <p className={`${styleName}`}>{text}</p> : null;
};
