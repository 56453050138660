import React from 'react';
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.br';
import cart from '../utils/cartPost';
import checkPack from '../utils/checkPack';
import mask from '../utils/mask';
import Icon from '../components/Icon';
import { PaymentStatus } from '../utils/axiosRetry';

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldError, values, validateForm }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  placeholder,
  ...props
}) => {
  const paymentStatus = values.paymentRequest.status;
  const disablePaymentBtn = PaymentStatus.started === paymentStatus || PaymentStatus.success === paymentStatus;

  return (
    <React.Fragment>
      {placeholder === 'CPF' && (
        <Cleave
          {...field}
          {...props}
          data-private="lipsum"
          placeholder={placeholder}
          options={{
            blocks: [3, 3, 3, 2],
            delimiters: ['.', '.', '-'],
            numericOnly: true
          }}
          //pattern="[0-9.-_]*"
          inputMode="numeric"
          onChange={(e) => {
            setFieldValue(field.name, e.target.rawValue);
          }}
        />
      )}
      {(placeholder === 'celular / whatsapp' || placeholder === 'telefone') && (
        <Cleave
          {...field}
          {...props}
          placeholder={'( __ ) ____-____'}
          options={{
            blocks: [99999],
            delimiter: ''
          }}
          value={mask.phoneMask(field.value)}
          inputMode="numeric"
          onChange={(e) => {
            setFieldValue(field.name, e.target.rawValue);
          }}
        />
      )}
      {placeholder === 'CEP' && (
        <Cleave
          {...field}
          {...props}
          placeholder={placeholder}
          options={{
            blocks: [5, 3],
            delimiters: ['-'],
            numericOnly: true
          }}
          pattern="[0-9-]*"
          inputMode="numeric"
          onChange={(e) => setFieldValue(field.name, e.target.rawValue)}
          onKeyPress={(event) => {
            let code = event.keyCode === 13 || event.which;
            if (code === 13) {
              if (values.shipping_info.address.zip_code.length === 8) {
                setFieldValue('show_cep', false);
                cart.cartPost(values, 'ship_calculator', setFieldValue).then((result) => {
                  if (!result?.shipping_info?.address?.city) {
                    setFieldError('shipping_info.address.zip_code', `CEP inválido`);
                  } else {
                    if (result?.delivery_options?.length !== 0) {
                      setFieldValue('show_cep_result', true);
                      setFieldValue('fixFields', '');
                    }
                  }
                });
              } else if (values?.shipping_info?.address?.zip_code?.length === 0) {
                setFieldError('recipient_CEP', `CEP obrigatório`);
              } else {
                setFieldError('recipient_CEP', `CEP inválido`);
              }
            }
          }}
        />
      )}
      {placeholder === 'data de nascimento' && (
        <Cleave
          {...field}
          {...props}
          data-private="lipsum"
          placeholder={placeholder}
          options={{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }}
          pattern="[0-9\/]*"
          inputMode="numeric"
          onChange={(e) => setFieldValue(field.name, e.target.value)}
        />
      )}
      {placeholder === 'número do cartão' && (
        <Cleave
          {...field}
          {...props}
          data-private="lipsum"
          placeholder={placeholder}
          options={{
            creditCard: true
          }}
          pattern="[0-9 ]*"
          inputMode="numeric"
          onChange={(e) => setFieldValue(field.name, e.target.rawValue)}
        />
      )}
      {placeholder === 'código de segurança (CVV)' && (
        <Cleave
          {...field}
          {...props}
          data-private="lipsum"
          placeholder={placeholder}
          options={{
            numericOnly: true
          }}
          pattern="[0-9]*"
          inputMode="numeric"
          onChange={(e) => setFieldValue(field.name, e.target.rawValue)}
        />
      )}
      {field.name === 'button_frete' && (
        <button
          type="button"
          className="button_frete"
          onClick={(event) => {
            if (values.shipping_info.address.zip_code.length === 8) {
              cart.cartPost(values, 'ship_calculator', setFieldValue).then((result) => {
                if (!result?.shipping_info?.address?.city) {
                  setFieldError('shipping_info.address.zip_code', `CEP inválido`);
                } else {
                  if (result?.delivery_options?.length !== 0) {
                    setFieldValue('show_cep_result', true);
                    setFieldValue('fixFields', '');
                  }
                }
              });
            } else if (values.shipping_info.address.zip_code.length === 0) {
              setFieldError('shipping_info.address.zip_code', `CEP obrigatório`);
            } else {
              setFieldError('shipping_info.address.zip_code', `CEP inválido`);
            }
          }}
        >
          OK
        </button>
      )}
      {field.name === 'button_envio' && (
        <button
          type="submit"
          className="button__envio"
          onClick={() => {
            checkPack.checkAddress(values, setFieldValue);
          }}
        >
          <span>salvar</span> endereço
        </button>
      )}
      {['button_credit_card', 'button_boleto'].includes(field.name) && !values.isLoadingForm && (
        <button
          className="progress-btn dados_customer_button"
          type="submit"
          disabled={disablePaymentBtn ? 'disabled' : ''}
          onClick={(event) => {
            if (!values.card_number) {
              setFieldError('card_number', 'Número do cartão obrigatório');
            }
            if (!values.card_name) {
              setFieldError('card_name', 'Nome obrigatório');
            }
            if (!values.card_expirationMonth) {
              setFieldError('card_expirationMonth', 'Mês obrigatório');
            }
            if (!values.card_expirationYear) {
              setFieldError('card_expirationYear', 'Ano obrigatório');
            }
            if (!values.card_cvv) {
              setFieldError('card_cvv', 'Código obrigatório');
            }
            if (!values.card_document) {
              setFieldError('card_document', 'CPF obrigatório');
            }
            if (!values.card_birthday) {
              setFieldError('card_birthday', 'Data de nascimento obrigatória');
            }
            checkPack.checkAwards(values, setFieldValue);
            checkPack.checkStock(values, setFieldValue);
            if (field.name === 'button_boleto') {
              checkPack.checkBoleto(values, setFieldValue, event);
            } else {
              checkPack.checkPayment(values, setFieldValue, event);
            }
          }}
        >
          {values.paymentRequest.status === PaymentStatus.error && (
            <>
              <strong>erro no pagamento</strong>
              <Icon name="cross" />
            </>
          )}
          {values.paymentRequest.status === PaymentStatus.not_started && <strong>finalizar pedido</strong>}
          {[PaymentStatus.started, PaymentStatus.success].includes(values.paymentRequest.status) && (
            <>
              <strong>finalizando...</strong>
              <div className="progress" style={{ width: `${values.progress}` }}></div>
            </>
          )}
        </button>
      )}
    </React.Fragment>
  );
};

export default CustomInputComponent;
