const mask = {
  phoneMask: function (value) {
    let result = value;
    result = result.replace('+55', '');
    result = result.replace(/\D/g, ''); //Remove tudo o que não é dígito
    result = result.replace(/^(\d\d)(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
    if (result[5] === '9') {
      result = result.replace(/(\d{5})(\d)/, '$1-$2'); //Coloca hífen entre o quinto e o sexto dígitos
    } else {
      result = result.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
    }
    return result;
  }
};

export default mask;
