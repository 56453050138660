import React, { PureComponent } from 'react';
import { Formik, Form } from 'formik';
import { Field } from 'formik';
import CustomInputComponent from '../src/components/CustomInputComponent';
import * as Sentry from '@sentry/react';

import CustomerPage from './form/customer';
import LocationPage from './form/envio';
import PaymentPage from './form/pagamento';
import SummaryPage from './form/summary';

import axios from 'axios';
import cartCookies from './utils/cartCookies';
import Loading from './utils/loading';
import siteTracking from '../src/utils/siteTracking';

import logo from '../src/img/logo-sallve.png';
import padlock_logo from '../src/img/seguro.svg';
import ErrorHandler from '../src/components/ErrorHandle';
import { BACKEND_URL } from './api';
import RetryModal from './components/Modal';
import { PaymentStatus } from './utils/axiosRetry';

class WizardForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isDekstop: '',
      isLoading: true,
      infoCookie: {},
      errorGetCookie: false
    };
  }
  componentDidMount() {
    this.initCart();
  }

  initCart = () => {
    let cart_id;

    if (this.getUrlParams()) {
      cart_id = this.getUrlParams();
      this.removeParamsAndPush(window.location.href);
    } else {
      try {
        cart_id = cartCookies.getCart().id;
      } catch (error) {
        this.setState({ errorGetCookie: true });
        Sentry.captureException(error);
      }
    }

    if (cart_id) {
      Sentry.setUser({ id: cart_id });

      axios
        .get(`${BACKEND_URL}/cart/?cart_id=${cart_id}`)
        .then((response) => {
          cartCookies.setSallveCart(response.data);
          siteTracking.checkoutStart(response.data);
          this.setState({ infoCookie: response.data });
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          Sentry.captureException(error);
          this.setState({ errorGetCookie: true });
          let errorString = `#${cart_id?.toString()}#`;
          errorString += error.toString();
          errorString += ' - form.js - ';
          window.ga('create', 'UA-127195310-3', 'auto');
          window.ga('set', {
            page: '/error',
            title: errorString
          });
          window.ga('send', 'pageview');
        });
    } else {
      this.setState({ isLoading: false, infoCookie: { products: [] } });
    }
  };

  getUrlParams = () => {
    let params = new URL(document.location).search;
    let searchParams = new URLSearchParams(params);

    if (params?.includes('cart_id')) {
      return searchParams.get('cart_id').split('?')[0];
    }
  };

  removeParamsAndPush = (url) => {
    let url_new = url.split('?')[0];
    window.history.pushState('', document.title, url_new);
  };

  render() {
    return (
      <React.Fragment>
        <React.Fragment>
          {Object.keys(this.state.infoCookie).length > 0 && this.state.infoCookie.constructor === Object ? (
            <div>
              <Formik
                enableReinitialize
                isInitialValid
                initialValues={{
                  address: {},
                  awards: {},
                  amountProducts: '',
                  code: '',
                  disabled: true,
                  discount_code: {},
                  disableOrder: true,
                  email_suggestion: '',
                  errorCart: false,
                  errorGetCookie: this.state.errorGetCookie,
                  errorRetry: false,
                  id: '',
                  gift: this.state.infoCookie.gift || false,
                  infoCookie: this.state.infoCookie,
                  isCartEmpty: false,
                  isDekstop: this.state.isDekstop,
                  isLoadingForm: false,
                  fixFields: '',
                  full_ship_chosen: {},
                  paymentCardOption: false,
                  products: this.state.infoCookie.products || [],
                  quantity_product: '',
                  tos: false,
                  show_cupom_field: false,
                  step1_show: true,
                  step2_show: false,
                  step3_show: false,
                  show_cep_result: false,
                  show_ship_rate_result: false,
                  show_neighborhood: false,
                  summaryVisible: true,
                  typePhone: '',
                  totals: {},
                  customer: {
                    email: '',
                    first_name: '',
                    last_name: '',
                    document: '',
                    phone: '',
                    accept_marketing: true,
                    accept_whatsapp: true
                  },

                  shipping_info: {
                    person: {
                      full_name: '',
                      phone: ''
                    },
                    address: {
                      zip_code: '',
                      street_number: null,
                      city: '',
                      neighborhood: '',
                      street: '',
                      state: '',
                      state_short: '',
                      complement: ''
                    }
                  },

                  billing_info: {
                    person: {
                      full_name: '',
                      phone: '',
                      document: '',
                      birth_date: null
                    }
                  },

                  trackings: {
                    utm_campaign: '',
                    utm_source: '',
                    utm_medium: '',
                    utm_term: '',
                    utm_content: ''
                  },

                  chosen_delivery_option: null,
                  delivery_options: [],

                  card_birthday: '',
                  card_cvv: '',
                  card_document: '',
                  card_expirationMonth: '',
                  card_expirationYear: '',
                  card_name: '',
                  card_number: '',
                  card_phone: '',
                  installment_chosen: 1,
                  payment_options: [],
                  payment_chosen: 'credit_card',
                  paymentRequest: { status: PaymentStatus.not_started },
                  progress: 0
                }}
                onSubmit={(values, actions) => {}}
                render={(formikProps, validateField, isInitialValid) => (
                  <Form>
                    <ErrorHandler {...formikProps}>
                      <React.Fragment>
                        <div className="logo_container">
                          <a href="https://www.sallve.com.br/">
                            <img className="logo" src={logo} alt="Logo Sallve" />
                          </a>
                          <img className="form_icon padlock_logo" src={padlock_logo} alt="Logo compra segura" />
                        </div>
                        <div className="dados">
                          <React.Fragment>
                            {!formikProps.values.isCartEmpty ? (
                              <div className="dados_container_left-middle">
                                <h2>finalizar pedido</h2>
                                <div className="dados_left">
                                  <CustomerPage {...formikProps} validateField={validateField} />
                                  <LocationPage {...formikProps} />
                                </div>
                                <div
                                  className="dados_middle"
                                  style={{
                                    display: formikProps.values.isCartEmpty ? 'none' : 'unset',
                                    backgroundColor: formikProps.values.step3_show === false ? '#f8f4fa' : '#ffffff'
                                  }}
                                >
                                  <PaymentPage {...formikProps} />
                                </div>
                              </div>
                            ) : (
                              <EmptyCart />
                            )}
                            <div className="dados_right">
                              <SummaryPage {...formikProps} />
                              <div className="finalizar_pedido">
                                {formikProps.values.step3_show ? (
                                  <React.Fragment>
                                    {formikProps.values.fixFields !== '' && (
                                      <div className="fix-payments m-t-15">
                                        <h2 className="fix-fields">{formikProps.values.fixFields}</h2>
                                      </div>
                                    )}
                                    {formikProps.values.payment_chosen === 'boleto' ? (
                                      <Field name="button_boleto" component={CustomInputComponent} />
                                    ) : (
                                      <React.Fragment>
                                        <Field name="button_credit_card" component={CustomInputComponent} />
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment />
                                )}
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </React.Fragment>
                    </ErrorHandler>
                    <RetryModal
                      show={formikProps.values.paymentRequest.status === PaymentStatus.error}
                      setFieldValue={formikProps.setFieldValue}
                      errors={formikProps.values.paymentRequest.errors}
                      values={formikProps.values}
                    />
                  </Form>
                )}
              />
            </div>
          ) : (
            <Loading />
          )}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

function EmptyCart() {
  return (
    <div className="full-empty">
      <div className="first-section">
        <div>
          <p className="woocommerce-mini-cart__empty-message" style={{ marginBottom: '0' }}>
            ops!
            <br /> sua sacola ainda está vazia.
            <br /> <span>vem cá descobrir nossas fórmulas e escolher a sua.</span>
          </p>
          <a className="button_empty-cart" href="https://www.sallve.com.br/collections/loja">
            escolher produtos
          </a>
        </div>
      </div>
      <div className="second-section">
        <div className="sidebar-right">
          <div className="sidebar__inner">
            <p className="cart-title-upsell">uma sugestão para você</p>
            <div
              className="product_item--inner"
              style={{
                maxHeight: 'fitContent',
                height: 'fitContent'
              }}
            >
              <div className="product_item--thumbnail">
                <div className="product_item--thumbnail-holder" style={{ width: 'fitContent', margin: '0 auto' }}>
                  <a
                    href="https://www.sallve.com.br/collections/loja/products/serum-uniformizador"
                    className="product__link"
                    tabIndex="0"
                  >
                    <img
                      alt="Sérum Uniformizador"
                      id="loja-image1-serum-antiacne"
                      className="wp-post-image loading lazyload"
                      src="//cdn.shopify.com/s/files/1/0074/3486/2639/products/1.jpg"
                      data-src="//cdn.shopify.com/s/files/1/0074/3486/2639/products/1.jpg"
                    />
                    <img
                      alt="Sérum Uniformizador"
                      id="loja-image2-serum-antiacne"
                      className="wp-alt-image loading lazyload"
                      data-src="//cdn.shopify.com/s/files/1/0074/3486/2639/products/1.jpg"
                      src="//cdn.shopify.com/s/files/1/0074/3486/2639/products/1.jpg"
                    />
                  </a>
                </div>
                <div className="product_item--info" id="1359102246959">
                  <div className="product_item--info-inner text-center">
                    <h2 className="product-item__info title">
                      <a
                        href="https://www.sallve.com.br/collections/loja/products/serum-uniformizador"
                        tabIndex="0"
                        id="loja-title-serum-antiacne"
                      >
                        Sérum Uniformizador
                      </a>
                    </h2>
                    <p className="product-item__info description">menos manchas, mais textura uniforme</p>
                    <h3 className="product-item__info preco">
                      R$ 89,90 <br />
                      <span className="text-divided_price">ou 3x R$ 29,​96</span>
                    </h3>
                  </div>
                </div>
              </div>
              <a
                id="shopping-bag"
                className="product-item__btn"
                style={{ backgroundColor: '#FA8CFF', color: '#333' }}
                href="https://www.sallve.com.br/collections/loja/products/serum-uniformizador"
                rel="nofollow"
                tabIndex="0"
                title="comprar"
              >
                comprar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sentry.withProfiler(WizardForm);
