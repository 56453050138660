import React, { Component } from 'react';
import { Field } from 'formik';
import CustomInputComponent from '../components/CustomInputComponent';
import checkPack from '../utils/checkPack';
import validator from '../utils/validators';
import house_icon from '../img/entrega.svg';

function fullAddress(street, streetNumber) {
  return street.concat(', ', streetNumber);
}

function goingToReduce(fullAddress) {
  let screenLengthToReduce = [320, 360, 375, 384];
  if (screenLengthToReduce.includes(window.innerWidth) && fullAddress.length > 35) {
    return true;
  }
  return false;
}

function reduceAddressLength(fullAddress) {
  let maxAddressLength = fullAddress.length;
  if ([360, 375, 384].includes(window.innerWidth) && fullAddress.length > 41) {
    maxAddressLength = 41;
  } else if (window.innerWidth === 320 && fullAddress.length > 35) {
    maxAddressLength = 35;
  } else {
    maxAddressLength = fullAddress.length;
  }

  return fullAddress ? fullAddress.slice(0, maxAddressLength) : fullAddress;
}

function updateAddressLength(action) {
  if (action === 'show') {
    document.getElementById('show_address').style.display = 'inline';
    document.getElementById('hidden_address').style.display = 'none';
  } else if (action === 'hide') {
    document.getElementById('show_address').style.display = 'none';
    document.getElementById('hidden_address').style.display = 'inline';
  }
}

class LocationPage extends Component {
  render() {
    return (
      <div
        className="dados_entrega"
        id="dados_entrega"
        style={{
          backgroundColor: this.props.values.step2_show === false ? '#f8f4fa' : '#ffffff',
          alignItems: 'baseline'
        }}
      >
        <h2>
          <img className="form_icon" src={house_icon} alt="ícone casa" /> endereço de entrega
        </h2>
        <Field
          name="shipping_fields"
          render={({ form }) =>
            form.values.step2_show ? (
              <React.Fragment>
                {!form.values.show_cep_result ? (
                  <React.Fragment>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row'
                      }}
                    >
                      <label className="label_CEP" id="CEP">
                        <p> CEP </p>
                        <Field
                          className="dados_entrega--CEP"
                          type="text"
                          name="shipping_info.address.zip_code"
                          placeholder="CEP"
                          component={CustomInputComponent}
                          validate={validator.validateCEP}
                          autoComplete="shipping postal-code"
                          inputMode="numeric"
                          onKeyUp={() => {
                            if (this.props.values.shipping_info.address.zip_code.length < 8) {
                              this.props.setFieldValue('show_cep_result', false);
                            }
                          }}
                          style={{
                            color: form.errors.shipping_info?.address?.zip_code ? '#C31E1E' : '#333333',
                            border: form.errors.shipping_info?.address?.zip_code
                              ? '1px solid #C31E1E'
                              : '1px solid #999999'
                          }}
                        />
                        {form.errors.shipping_info?.address?.zip_code && <i className="fa fa-exclamation-triangle" />}
                      </label>
                      <Field
                        name="button_frete"
                        type="button"
                        component={CustomInputComponent}
                        style={{
                          marginBottom: '10px'
                        }}
                      />
                      <a
                        href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="search-cep"
                        id="search-cep"
                      >
                        não sei meu cep
                      </a>
                    </div>
                    {form.values.fixFields !== '' && (
                      <div className="fix-payments">
                        <h2 className="fix-fields no-margin"> {form.values.fixFields} </h2>
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <p className="zip_code_link-text"> CEP: </p>
                      <a
                        className="zip_code_link"
                        onClick={() => {
                          this.props.setFieldValue('show_cep_result', false);
                        }}
                      >
                        {this.props.values.shipping_info.address.zip_code.replace(/(\d{5})(\d{3})/, '$1-$2')}
                      </a>
                    </div>
                  </React.Fragment>
                )}
                <Field
                  name="shipping_fields"
                  render={({ form }) =>
                    form.values.show_ship_rate_result && form.values.show_cep_result ? (
                      <div className="dados_entrega--info">
                        <p className="dados_entrega--full-description">
                          {form.values.shipping_info.address.street}
                          {form.values.shipping_info.address.street_number ? ', ' : ''}
                          {form.values.shipping_info.address.street_number}
                          {form.values.shipping_info.address.complement ? ', ' : ''}
                          {form.values.shipping_info.address.complement} <br />
                          {form.values.shipping_info.address.neighborhood
                            ? form.values.shipping_info.address.neighborhood + ' • '
                            : ''}
                          {form.values.shipping_info.address.city}/ {form.values.shipping_info.address.state_short}
                        </p>
                        <div className="dados_entrega--info-1st">
                          {form.values.show_street === true && (
                            <React.Fragment>
                              <label className="dados_entrega--street">
                                <p> endereço </p>
                                <Field
                                  type="text"
                                  name="shipping_info.address.street"
                                  placeholder="rua"
                                  validate={validator.validateComplement}
                                  autocomplete="shipping street-address"
                                  value={
                                    this.props.values.shipping_info.address.street == null
                                      ? this.props.values.shipping_info.address.street
                                      : this.props.values.shipping_info.address.street.replace('  ', ' ')
                                  }
                                  style={{
                                    color: form.errors.shipping_info?.address?.street ? '#C31E1E' : '#333333',
                                    border: form.errors.shipping_info?.address?.street
                                      ? '1px solid #C31E1E'
                                      : '1px solid #999999'
                                  }}
                                />
                                {form.errors.shipping_info?.address?.street && (
                                  <i className="fa fa-exclamation-triangle" />
                                )}
                              </label>
                            </React.Fragment>
                          )}
                          <div>
                            <label className="recipient_street_number--label">
                              <p> número </p>
                              <Field
                                value={
                                  this.props?.values?.shipping_info?.address?.street_number
                                    ? this.props?.values?.shipping_info?.address?.street_number
                                    : ''
                                }
                                className="recipient_street_number"
                                type="text"
                                name="shipping_info.address.street_number"
                                placeholder="número"
                                validate={validator.validateStreetNum}
                                autoComplete="address-line3"
                                inputMode="numeric"
                                style={{
                                  color: form.errors.shipping_info?.address?.street_number ? '#C31E1E' : '#333333',
                                  border: form.errors.shipping_info?.address?.street_number
                                    ? '1px solid #C31E1E'
                                    : '1px solid #999999'
                                }}
                              />
                              {form.errors.shipping_info?.address?.street_number && (
                                <i className="fa fa-exclamation-triangle" />
                              )}
                            </label>
                          </div>
                          <div>
                            <label className="recipient_complement--label">
                              <p> complemento </p>
                              <Field
                                className="recipient_complement"
                                type="text"
                                name="shipping_info.address.complement"
                                placeholder="Apt 123 Bl A"
                                value={
                                  this.props.values.shipping_info.address.complement == null
                                    ? this.props.values.shipping_info.address.complement
                                    : this.props.values.shipping_info.address.complement.replace('  ', ' ')
                                }
                              />
                            </label>
                          </div>
                        </div>
                        <div className="dados_entrega--info-2st">
                          {form.values.show_neighborhood === true && (
                            <React.Fragment>
                              <label>
                                <p> bairro </p>
                                <Field
                                  type="text"
                                  name="shipping_info.address.neighborhood"
                                  validate={validator.validateComplement}
                                  placeholder="bairro"
                                  value={
                                    this.props.values.shipping_info.address.neighborhood == null
                                      ? this.props.values.shipping_info.address.neighborhood
                                      : this.props.values.shipping_info.address.neighborhood.replace('  ', ' ')
                                  }
                                  style={{
                                    color: form.errors.shipping_info?.address?.neighborhood ? '#C31E1E' : '#333333',
                                    border: form.errors.shipping_info?.address?.neighborhood
                                      ? '1px solid #C31E1E'
                                      : '1px solid #999999'
                                  }}
                                />
                                {form.errors.shipping_info?.address?.neighborhood && (
                                  <i className="fa fa-exclamation-triangle" />
                                )}
                              </label>
                            </React.Fragment>
                          )}
                          <label className="dados_entrega--full-name">
                            <p> nome do destinatário </p>
                            <Field
                              type="text"
                              name="shipping_info.person.full_name"
                              placeholder="destinatário"
                              validate={validator.validateFullName}
                              style={{
                                color: form.errors.shipping_info?.person?.full_name ? '#C31E1E' : '#333333',
                                border: form.errors.shipping_info?.person?.full_name
                                  ? '1px solid #C31E1E'
                                  : '1px solid #999999'
                              }}
                            />
                            {form.errors.shipping_info?.person?.full_name && (
                              <i className="fa fa-exclamation-triangle" />
                            )}
                          </label>
                        </div>
                        <label className="customer_email-marketing gift">
                          <Field
                            className="regular-checkbox"
                            type="checkbox"
                            name="gift"
                            placeholder="gift"
                            onChange={(event) => form.setFieldValue('gift', event.target.checked)}
                            checked={form.values?.gift}
                          />
                          <div className="text-checkbox">
                            <p className="lato-bold">
                              estou presenteando alguém
                              <span role="img" aria-label="imagem de uma embalagem de presente">
                                🎁
                              </span>
                            </p>
                            <p className="lato-italic">
                              um cartão de presente gratuito será adicionado na sua sacola :)
                            </p>
                          </div>
                        </label>
                        <div className="ship_container">
                          <Field
                            name="ship_options"
                            render={({ form }) =>
                              form.values.delivery_options != null &&
                              form.values.delivery_options.map((ship) => (
                                <label key={ship.delivery_method} className="ship_options">
                                  <input
                                    type="radio"
                                    key={ship.id}
                                    id={ship.id}
                                    value={ship.shipping_discount_price}
                                    name="delivery_options"
                                    defaultChecked
                                    className="form-check-input"
                                    onClick={(event) => {
                                      form.setFieldValue('full_ship_chosen', ship);
                                    }}
                                  />
                                  <p className="ship_options-1st-line">
                                    {ship?.description?.toLowerCase()}
                                    &nbsp;•&nbsp;
                                    {form.values.totals.shipping - form.values.totals.shipping_discount === 0
                                      ? 'grátis'
                                      : 'R$ ' +
                                        (form.values.totals.shipping - form.values.totals.shipping_discount)
                                          .toFixed(2)
                                          .replace('.', ',')}
                                  </p>
                                  <p>
                                    {form.values?.delivery_options[0]?.estimate_business_days === 0 ? (
                                      'chega hoje! :)'
                                    ) : (
                                      <>
                                        entregue via&nbsp;
                                        {this.props.values.chosen_delivery_option.id === 1 ||
                                        this.props.values.chosen_delivery_option.id === 2
                                          ? 'Correios'
                                          : 'Transportadora'}
                                        <span className="lato-bold">
                                          &nbsp;em até {ship?.estimate_business_days} dias úteis
                                        </span>
                                      </>
                                    )}
                                  </p>
                                </label>
                              ))
                            }
                          />
                        </div>
                        {form.values.fixFields !== '' && (
                          <div className="fix-payments">
                            <h2 className="fix-fields"> {form.values.fixFields} </h2>
                          </div>
                        )}
                        <Field
                          name="button_save_customer"
                          render={({ form }) => (
                            <button
                              className="button__envio"
                              onClick={() => {
                                checkPack.checkAddress(form, form.setFieldValue);
                              }}
                            >
                              <strong> salvar endereço </strong>
                            </button>
                          )}
                        />
                      </div>
                    ) : (
                      <React.Fragment />
                    )
                  }
                />
              </React.Fragment>
            ) : (
              <Field
                name="shipping_fields"
                render={({ form }) =>
                  !form.values.step2_show &&
                  !validator.validateCEP(form.values.shipping_info.address.zip_code) &&
                  form.values.shipping_info.address.street_number &&
                  !validator.validateStreetNum(form.values.shipping_info.address.street_number) &&
                  form.values.shipping_info.address.street &&
                  !validator.validateComplement(form.values.shipping_info.address.street) &&
                  form.values.shipping_info.address.neighborhood &&
                  !validator.validateComplement(form.values.shipping_info.address.neighborhood) ? (
                    <div className="dados_envio--full-description">
                      <p>
                        {goingToReduce(
                          fullAddress(
                            form.values.shipping_info.address.street,
                            form.values.shipping_info.address.street_number
                          )
                        ) === true ? (
                          <React.Fragment>
                            <span id="hidden_address">
                              {reduceAddressLength(
                                fullAddress(
                                  form.values.shipping_info.address.street,
                                  form.values.shipping_info.address.street_number
                                )
                              )}
                              <span
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer'
                                }}
                                onClick={() => updateAddressLength('show')}
                              >
                                ...
                              </span>
                            </span>
                            <span
                              id="show_address"
                              onClick={() => updateAddressLength('hide')}
                              style={{
                                display: 'none'
                              }}
                            >
                              {fullAddress(
                                form.values.shipping_info.address.street,
                                form.values.shipping_info.address.street_number
                              )}
                            </span>
                          </React.Fragment>
                        ) : (
                          fullAddress(
                            form.values.shipping_info.address.street,
                            form.values.shipping_info.address.street_number
                          )
                        )}
                        <br />
                        {form.values.shipping_info.address.complement !== ''
                          ? form.values.shipping_info.address.complement + ', '
                          : ''}
                        {form.values.shipping_info.address.neighborhood} <br /> {form.values.shipping_info.address.city}
                        {'/ '} {form.values.shipping_info.address.state_short + ', '}
                        {form.values.shipping_info.address.zip_code.replace(/(\d{5})(\d{3})/, '$1-$2')}
                      </p>
                      <p className="margin-top-10 margin-bottom-8">
                        {form.values?.delivery_options[0]?.description} •&nbsp;
                        {form.values.totals.shipping - form.values.totals.shipping_discount === 0
                          ? 'grátis'
                          : 'R$ ' +
                            (form.values.totals.shipping - form.values.totals.shipping_discount)
                              .toFixed(2)
                              .replace('.', ',')}
                        {' • '}
                        {form.values?.delivery_options[0]?.estimate_business_days === 0 ? (
                          'chega hoje! :)'
                        ) : (
                          <>
                            em até {form.values?.delivery_options[0]?.estimate_business_days} dias úteis via&nbsp;
                            {this.props.values.chosen_delivery_option.id === 1 ||
                            this.props.values.chosen_delivery_option.id === 2
                              ? 'Correios'
                              : 'Transportadora'}
                          </>
                        )}
                      </p>
                      {form.values.gift && (
                        <p className="lato-bold">
                          você está presenteando alguém
                          <span role="img" aria-label="imagem de uma embalagem de presente">
                            🎁
                          </span>
                        </p>
                      )}
                      <a
                        className="button__change"
                        onClick={() => {
                          form.setFieldValue('step1_show', false);
                          form.setFieldValue('step2_show', true);
                          form.setFieldValue('step3_show', false);
                          form.setFieldValue('fixFields', '');
                        }}
                      >
                        alterar
                      </a>
                    </div>
                  ) : (
                    <p className="dados_aguardando">
                      aguardando o preenchimento de <strong> seus dados </strong>
                    </p>
                  )
                }
              />
            )
          }
        />
      </div>
    );
  }
}

export default LocationPage;
