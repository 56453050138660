import React from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { IS_DEV } from './api';
import Form from './form';

import pkg from '../package.json';

import 'font-awesome/css/font-awesome.min.css';
import './app.css';

if (!IS_DEV) {
  Sentry.init({
    dsn: 'https://88fe8d368c9d47ac8431f4c18eae50ac@o434257.ingest.sentry.io/5391029',
    release: `${pkg.name}@${pkg.version}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV === 'development' ? 'development' : 'production'
  });

  LogRocket.init('jyykav/sallve');
}

ReactDOM.render(<Form />, document.getElementById('root'));
