const cartCookies = {
  getCart: function () {
    let nameEQ = 'sallvecart=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return JSON.parse(unescape(c.substring(nameEQ.length, c.length)));
    }
    return {
      products: []
    };
  },
  getUTM: function () {
    let nameEQ = 'sallve_utm=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return JSON.parse(unescape(c.substring(nameEQ.length, c.length)));
    }
    return {
      trackings: {}
    };
  },
  setSallveCart: function (result) {
    let expires = '';
    let date = new Date();
    date.setTime(date.getTime() + 14 * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
    document.cookie =
      'sallvecart=' +
      escape(JSON.stringify({ id: result.id })) +
      '; domain=' +
      this.getCookieDomain() +
      '; path=/;' +
      expires;
  },
  cleanCookie: function () {
    document.cookie =
      'sallvecart=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=' + this.getCookieDomain() + '; path=/;';
  },
  getCookieDomain: function () {
    if (window.location.hostname === 'localhost') {
      return 'localhost';
    } else {
      return '.sallve.com.br';
    }
  }
};

export default cartCookies;
