import React, { Component } from 'react';
import { Field } from 'formik';
import { captureException } from '@sentry/react';
import axios from 'axios';

import { BACKEND_URL } from '../api';

function isMini(item) {
  return (
    item.selectable && !item.surprise && !item.special_card && item.product.product_category.toUpperCase() === 'MINI'
  );
}

class Awards extends Component {
  addingItems = (sku, quantity) => {
    axios
      .post(`${BACKEND_URL}/cart/`, {
        id: this.props.values.id,
        products: [
          {
            sku: sku,
            quantity: quantity
          }
        ],
        discount_code: {
          code: this.props.values.code
        }
      })
      .then((response) => {
        this.props.setFieldValue('products', response.data.products);
        this.props.setFieldValue('awards', response.data.awards);
        this.props.setFieldValue('totals', response.data.totals);
      })
      .catch((error) => {
        captureException(error);
      });
  };

  awardData = (award) => {
    return (
      <div className="cart__info" key={award.misterious === true ? award.product.title : 'mimo misterioso'}>
        <div className={`${isMini(award) ? 'cart__container--image mini' : 'cart__container--image'}`}>
          <img
            className="cart__image"
            src={
              award.misterious !== true
                ? award.product.image
                : 'https://cdn.shopify.com/s/files/1/0074/3486/2639/files/sallve-img-mimo-misterioso_150x150.jpg'
            }
            alt="Imagem do produto"
          />
          {award.customer_tag === 'superBuyer' && award.valid_customer_tag === true ? (
            <span className="super_buyer super_buyer_star">★</span>
          ) : (
            <React.Fragment />
          )}
        </div>
        <div className="cart__container-title-quantity">
          <p className="cart__description">
            {award.misterious === true ? 'mimo misterioso' : award?.product?.title?.toLowerCase()}
            <i className="fa fa-gift fa-lg"></i>
          </p>
          <div className="cart__container--quantity-values">
            <div className="cart__container--quantity">
              <div className="cart__quantity quantity">
                <Field
                  className="input-text qty text item__value"
                  type="number"
                  name="quantity_product"
                  value={award.product.quantity}
                  min="1"
                  max="6"
                  pattern="[0-6]*"
                />
              </div>
            </div>
            <div className="cart__container--values">
              {award.product.price > 0 ? (
                <>
                  <div className="cart__item-price">
                    {' R$ '}
                    {parseFloat(Math.round(award.product.price * 100) / 100)
                      .toFixed(2)
                      .replace('.', ',')}
                  </div>
                  <div className="cart__total-price">
                    {' R$ '}
                    {parseFloat(Math.round(award.product.price * award.product.quantity * 100) / 100)
                      .toFixed(2)
                      .replace('.', ',')}
                  </div>
                </>
              ) : (
                <>
                  <div className="cart__item-price price-item-free">brinde</div>
                  <div className="cart__total-price">{award.first_buy === true ? '1ª compra' : 'grátis'}</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (Object.keys(this.props.values.awards).length === 0 && this.props.values.awards.constructor === Object) {
      return <React.Fragment />;
    }

    const selectableAwards = this.props.values.awards.products.filter((product) => product.selectable);
    const hasAutomaticAwards = this.props.values.awards.automatic_awards.length;
    const hasSelectableCoupon = this.props.values.awards.selectable_coupon;

    if (hasSelectableCoupon && hasAutomaticAwards.length > 0 && selectableAwards.length > 0) {
      return selectableAwards
        .filter((award) => award.selected)
        .map((award) => <React.Fragment key={award.product.title}>{this.awardData(award)}</React.Fragment>);
    } else {
      return this.props.values.awards.products
        .filter(
          (award) =>
            (award.selected === true || award.selectable === false) &&
            award.valid_first_buy !== false &&
            award.valid_pre_condition !== false &&
            award.valid_customer_tag !== false &&
            award.surprise !== true
        )
        .map((award) => <React.Fragment key={award.product.title}>{this.awardData(award)}</React.Fragment>);
    }
  }
}

export default Awards;
