import axios from 'axios';
import { captureException } from '@sentry/react';

import jsonFactory from '../utils/jsonGenerator';
import setFields from '../utils/setFields';
import { successHandler, errorHandler } from '../utils/axiosRetry';
import cartCookies from '../utils/cartCookies';
import { BACKEND_URL } from '../api';

function catchCartRequest(error, type, state, setFieldValue) {
  captureException(error);
  if (type !== 'ship_calculator') {
    setFieldValue('errorCart', true);
  } else {
    setFieldValue('fixFields', 'CEP inválido ou não encontrado.');
  }
  cart.errorPost(state, error);
}

function persistOrderOrCart(state, type, setFieldValue) {
  let dataToSend = jsonFactory.jsonGenerator(state, type);
  let postType = type === 'boleto' ? 'order/sync' : 'cart';

  return axios
    .post(`${BACKEND_URL}/${postType}/`, dataToSend)
    .then((result) => {
      if (postType === 'cart') {
        setFields(type, setFieldValue, result.data);
        cartCookies.setSallveCart(result.data);
        return result.data;
      } else if (postType === 'order/sync') {
        successHandler(result, setFieldValue);
      }
    })
    .catch((result) => {
      if (postType === 'cart') {
        catchCartRequest(result, type, state, setFieldValue);
      } else {
        errorHandler({ errors: result.response.data.errors, cart: state, setFieldValue: setFieldValue });
      }
    });
}

const cart = {
  cartPost: function (state, type, setFieldValue) {
    if (type === 'boleto') {
      let shippingInfoData = jsonFactory.jsonGenerator(state, 'save_address');
      axios
        .post(`${BACKEND_URL}/cart/`, shippingInfoData)
        .then((result) => {
          return persistOrderOrCart(state, type, setFieldValue);
        })
        .catch((error) => catchCartRequest(error, type, state, setFieldValue));
    } else {
      return persistOrderOrCart(state, type, setFieldValue);
    }
  },

  errorPost: function (state, result) {
    let errorString = `#${state?.id?.toString()}#`;
    errorString += ' - cartPost.js - ';
    if (result.response) {
      let errorObject = {
        responseData: result.response.data,
        responseStatus: result.response.status,
        requestData: result.response.config.data,
        requestUrl: result.response.config.url,
        requestMethod: result.response.config.method
      };
      errorString += JSON.stringify(errorObject);
    } else {
      errorString += JSON.stringify(result);
    }
    window.ga('create', 'UA-127195310-3', 'auto');
    window.ga('set', {
      page: '/error',
      title: errorString
    });
    window.ga('send', 'pageview');
  }
};

export default cart;
