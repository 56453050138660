import format from '../utils/format';

export const getPhone = (state) => {
  let phone = state?.customer?.phone || state?.billing_info?.person?.phone || state?.shipping_info?.person?.phone;

  if (phone?.includes('+55')) {
    phone = phone.replace('+55', '');
  }

  return phone.replace(/\D/g, '');
};

const jsonFactory = {
  jsonGenerator: function (state, type) {
    let jsonGenerated;
    if (type === 'save_customer') {
      jsonGenerated = {
        id: state.id,
        customer: {
          first_name: state.customer.first_name.trim(),
          last_name: state.customer.last_name.trim(),
          document: state.customer.document.trim(),
          email: state.customer.email.trim(),
          phone: getPhone(state),
          accept_marketing: state.customer.accept_marketing,
          accept_whatsapp: state.customer.accept_whatsapp
        }
      };
    } else if (type === 'save_address') {
      jsonGenerated = {
        id: state.id,
        shipping_info: {
          ...state.shipping_info,
          person: {
            ...state.shipping_info.person,
            phone: getPhone(state)
          }
        },
        billing_info: state.billing_info,
        gift: state.gift
      };
    } else if (type === 'ship_calculator') {
      jsonGenerated = {
        shipping_info: {
          address: {
            ...state.shipping_info.address,
            zip_code: state.shipping_info.address.zip_code
          },
          person: {
            ...state.shipping_info.person,
            phone: getPhone(state)
          }
        },
        id: state.id
      };
    } else if (type === 'boleto') {
      jsonGenerated = {
        cart_id: state.id,
        payment_method: 'boleto',
        trackings: state.trackings,
        installments: 1,
        credit_card_hash: ''
      };
    } else if (type === 'product') {
      jsonGenerated = {
        id: state.id,
        products: state.products
      };
    } else if (type === 'card_data') {
      jsonGenerated = {
        id: state.id,
        billing_info: {
          person: {
            full_name: state.card_name,
            document: state.card_document,
            birth_date: format.FormataStringData(state.card_birthday),
            phone: getPhone(state)
          }
        },
        shipping_info: {
          ...state.shipping_info,
          person: {
            ...state.shipping_info.person,
            phone: getPhone(state)
          }
        }
      };
    } else if (type === 'code') {
      jsonGenerated = {
        id: state.id,
        discount_code: {
          code: state.code
        },
        products: state.products
      };
    }
    return jsonGenerated;
  },
  cardGenerator: function (state, hash, sallveCCHash) {
    let jsonGenerated;
    jsonGenerated = {
      cart_id: state.id,
      payment_method: 'credit_card',
      trackings: state.trackings,
      installments: state.installment_chosen,
      credit_card_hash: hash,
      sallve_cc_hash: sallveCCHash,
      store_credit_card: false
    };
    return jsonGenerated;
  }
};

export default jsonFactory;
