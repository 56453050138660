import { MoipValidator } from 'moip-sdk-js';

const validators = {
  calcFirstChecker(firstNineDigits) {
    var sum = null;
    for (var j = 0; j < 9; ++j) {
      sum += Number(firstNineDigits.toString().charAt(j)) * (10 - j);
    }
    var lastSumChecker1 = sum % 11;
    var checker1 = lastSumChecker1 < 2 ? 0 : 11 - lastSumChecker1;
    return checker1;
  },

  calcSecondChecker(cpfWithChecker1) {
    var sum = null;
    for (var k = 0; k < 10; ++k) {
      sum += Number(cpfWithChecker1.toString().charAt(k)) * (11 - k);
    }
    var lastSumChecker2 = sum % 11;
    var checker2 = lastSumChecker2 < 2 ? 0 : 11 - lastSumChecker2;
    return checker2;
  },

  validateBirth(value) {
    let error;
    var regex = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/;
    //Check whether valid dd/MM/yyyy Date Format.
    if (regex.test(value)) {
      var parts = value.split('/');
      var dtDOB = new Date(parts[1] + '/' + parts[0] + '/' + parts[2]);
      var dtCurrent = new Date();
      if (dtCurrent.getFullYear() - dtDOB.getFullYear() < 18) {
        error = 'ops, data inválida';
      }

      if (dtCurrent.getFullYear() - dtDOB.getFullYear() === 18) {
        //CD: 11/06/2018 and DB: 15/07/2000. Will turned 18 on 15/07/2018.
        if (dtCurrent.getMonth() < dtDOB.getMonth()) {
          error = 'ops, data inválida';
        }
        if (dtCurrent.getMonth() === dtDOB.getMonth()) {
          //CD: 11/06/2018 and DB: 15/06/2000. Will turned 18 on 15/06/2018.
          if (dtCurrent.getDate() < dtDOB.getDate()) {
            error = 'ops, data inválida';
          }
        }
      }
      return error;
    } else {
      error = 'digite a data de nascimento';
      return error;
    }
  },

  validateCardNumber(value) {
    let error;
    if (!value) {
      error = 'digite o número do seu cartão de crédito';
    } else if (!MoipValidator.isValidNumber(value)) {
      error = 'ops, verifique o número do seu cartão';
    }
    return error;
  },

  validateCvvNumber(cvv, number) {
    let error;
    if (!cvv || !number) {
      error = 'digite o código do seu cartão';
    } else if (!MoipValidator.isSecurityCodeValid(number, cvv)) {
      error = 'ops, o código parece incorreto';
    }

    return error;
  },

  validateCEP(value) {
    let error;
    if (!value) {
      error = 'CEP obrigatório';
    } else if (value.length < 8) {
      error = 'CEP inválido';
    }
    return error;
  },

  validateCPF(value) {
    let error;
    if (!value) {
      error = 'digite o CPF';
      return error;
    }

    if (typeof value !== 'string' && typeof value !== 'number') {
      error = 'ops, CPF inválido';
      return error;
    }

    var cleanCPF = String(value).replace(/\.|-|\s/g, '');
    var firstNineDigits = cleanCPF.substring(0, 9);
    var checker = cleanCPF.substring(9, 11);

    if (cleanCPF.length !== 11) {
      error = 'ops, CPF inválido';
      return error;
    } // Checking if all digits are equal

    for (var i = 0; i < 10; i++) {
      if ('' + firstNineDigits + checker === Array(12).join(String(i))) {
        error = 'ops, CPF inválido';
        return error;
      }
    }

    var checker1 = validators.calcFirstChecker(firstNineDigits);
    var checker2 = validators.calcSecondChecker('' + firstNineDigits + checker1);

    if (checker.toString() !== checker1.toString() + checker2.toString()) {
      error = 'ops, CPF inválido';
    }
    return error;
  },

  validateStreet(value) {
    let error;
    if (!value) {
      error = 'Rua obrigatório';
    } else if (value.length > 60) {
      error = 'Complemento tem no máximo 60 caracteres';
    }
    return error;
  },

  validateComplement(value) {
    let error;
    if (value.length > 60) {
      error = 'Complemento tem no máximo 60 caracteres';
    }
    return error;
  },

  validateCupom(value) {
    let error;
    if (value.length > 20) {
      error = 'Cupom tem no máximo 20 caracteres';
    }
    return error;
  },

  validateEmail(value) {
    let error;
    if (!value) {
      error = 'Email obrigatório';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = 'Email inválido';
    }
    return error;
  },
  validateFullName(value) {
    let error;
    if (!value) {
      error = 'digite o nome impresso no seu cartão';
    } else if (
      !/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ' ]{2,}[\s]{1,}[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]{1,}$/i.test(
        value
      )
    ) {
      error = 'ops, digite o nome impresso no seu cartão';
    } else if (value.length <= 4) {
      error = 'o nome impresso deve ter mais que 4 caracteres';
    } else if (value.length > 60) {
      error = 'o nome impresso deve ter menos que 60 caracteres';
    }
    return error;
  },
  validateName(value) {
    value = value.trim();
    let error;
    if (!value) {
      error = 'Nome e Sobrenome obrigatórios';
    } else if (value.length < 1 || value.length >= 41) {
      error = 'Nome inválido';
    } else if (!/^[A-Za-záàâãäéèêíïóôõöúüçñÁÀÂÃÄÉÈÊÍÏÓÔÕÖÚÜÇÑßØøäëïöüÿÄËÏÖÜŸ ]+$/i.test(value)) {
      error = 'Nome inválido';
    }
    return error;
  },
  validateLastName(value) {
    value = value.trim();
    let error;
    if (!value) {
      error = 'Nome e Sobrenome obrigatórios';
    } else if (value.length < 1 || value.length >= 61) {
      error = 'Nome inválido';
    } else if (!/^[A-Za-záàâãéèêíóôõúçñÁÀÂÃÉÈÊÍÓÔÕÚÇÑßØøäëïöüÿÄËÏÖÜŸ ]+$/i.test(value)) {
      error = 'Nome inválido';
    }
    return error;
  },

  validatePhone(value) {
    value = value.replace(/\D/g, ''); //Remove tudo o que não é dígito
    if (!value) {
      return 'Telefone obrigatório';
    }
    let valid_phone;
    if (value[2] === '9') {
      valid_phone = /\d{11}/.test(value);
    } else {
      valid_phone = /\d{10}/.test(value);
    }
    if (!valid_phone) {
      return 'Número inválido';
    }
  },

  validateStreetNum(value) {
    let error;
    if (!value) {
      error = 'Número obrigatório';
    } else if (!/^[0-9]*$/.test(value)) {
      error = 'Número inválido';
    } else if (value.length > 10) {
      error = 'Número inválido';
    }
    return error;
  }
};

export default validators;
