import React from 'react';
import { captureException } from '@sentry/react';
import axios from 'axios';

import logo from '../img/logo-sallve.png';
import padlock_logo from '../img/padlock-icon.png';
import customer_icon from '../img/customer-icon.svg';
import house_icon from '../img/entrega.svg';
import package_icon from '../img/sacola.svg';
import Awards from './Awards';
import { BACKEND_URL } from '../api';

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorOccurred: false,
      resposta: '',
      numberRetry: 0
    };
  }

  componentDidCatch(error, info) {
    captureException(error);
    this.setState({
      errorOccurred: true
    });
    this.props.setFieldValue(this.props.values.errorRetry, true);
    this.logErrorToMyService(error, info);
  }

  UNSAFE_componentDidUpdate(prevProps) {
    if (prevProps.values.errorRetry !== this.props.values.errorRetry && this.props.values.errorRetry) {
      this.fetchData();
    }
  }

  fetchData() {
    const MAX_RETRY = 30;
    axios
      .get(`${BACKEND_URL}/order/by-card-id?cart_id=${this.props.values.id}`)
      .then((result) => {
        if (
          result.data.transactions[0].method === 'credit_card' &&
          result.data.transactions[0].status === 'CANCELLED' &&
          !result.data.transactions[1]
        ) {
          setTimeout(() => this.fetchData(), 500);
        }
        this.setState({ resposta: result.data });
        this.setState({ numberRetry: this.state.numberRetry + 1 });
      })
      .catch((error) => {
        captureException(error);

        if (this.state.numberRetry < MAX_RETRY) {
          setTimeout(() => this.fetchData(), 500);
        } else {
          setTimeout(
            this.setState({
              resposta: null
            }),
            1500
          );
        }
        this.setState({ numberRetry: this.state.numberRetry + 1 });
      });
  }

  logErrorToMyService = (error, info) => {
    let errorString = error.toString();
    errorString = `#${this.props?.values?.id?.toString()}#`;
    errorString += ' - ErrorHandle.js - ';
    errorString += JSON.stringify(info);
    window.ga('create', 'UA-127195310-3', 'auto');
    window.ga('set', {
      page: '/error',
      title: errorString
    });
    window.ga('send', 'pageview');
  };

  onSummaryClick = (event) => {
    if (window.innerWidth <= 600) {
      this.props.setFieldValue('summaryVisible', !this.props.values.summaryVisible);
      this.setState((prevState) => ({
        summaryVisible: !prevState.summaryVisible
      }));
    }
  };

  render() {
    return this?.state?.errorOccurred ||
      this?.props?.values?.errorRetry ||
      this?.props?.values?.errorCart ||
      this?.props?.values?.errorGetCookie ? (
      <div
        style={{
          height: '100vh',
          alignItems: 'center'
        }}
      >
        <LogoContainer />
        <div className="error_screen" style={{ alignItems: 'center' }}>
          {this.props.values.errorRetry && this.state.resposta ? (
            <React.Fragment>
              {this.state.resposta.transactions[0].method === 'credit_card' ? (
                this.state.resposta.transactions[0].status === 'PAID' ? (
                  <React.Fragment>
                    <h1 className="title">seu pagamento foi aprovado</h1>
                    <p className="linha-fina">em breve você receberá também no seu e-mail uma confirmação do pedido</p>
                  </React.Fragment>
                ) : this.state.resposta.transactions[0].status === 'IN_ANALYSIS' ? (
                  <React.Fragment>
                    <h1 className="title">aguardando pagamento</h1>
                    <p className="linha-fina">
                      assim que sua operadora de cartão der sinal verde, a gente segue com o pedido. qualquer dúvida,
                      <span
                        style={{
                          textDecoration: 'underline',
                          fontWeight: 'bold'
                        }}
                      >
                        oi@sallve.com.br
                      </span>
                    </p>
                  </React.Fragment>
                ) : this.state.resposta.transactions[0].status === 'CANCELLED' &&
                  this.state.resposta.transactions[1] ? (
                  <React.Fragment>
                    <h1 className="title">ops! pagamento não confirmado</h1>
                    <p className="linha-fina">
                      não conseguimos a confirmação do pagamento com a operadora do seu cartão de crédito. mas você
                      ainda pode
                      <span
                        style={{
                          fontWeight: 'bold'
                        }}
                      >
                        realizar o pagamento através de boleto bancário.
                      </span>
                    </p>

                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="boleto"
                      style={{
                        fontWeight: 'bold'
                      }}
                      href={this.state.resposta.transactions[1].details.boleto_url}
                    >
                      acessar link do boleto <i className="fa fa-barcode"></i>
                    </a>
                  </React.Fragment>
                ) : (
                  setTimeout(
                    <h1 className="title">
                      &quot;Estamos processando seu pedido e em breve você receberá uma confirmação por email&quot;
                    </h1>,
                    1000
                  )
                )
              ) : (
                <React.Fragment>
                  <h1 className="title">eba! agora é só pagar seu boleto</h1>
                  <p className="linha-fina">
                    pagamentos por boleto demoram um tempo para aparecer. assim que ele aparecer por aqui, te avisamos e
                    seguimos com o pedido. Qualquer dúvida,
                    <span
                      style={{
                        textDecoration: 'underline',
                        fontWeight: 'bold'
                      }}
                    >
                      oi@sallve.com.br
                    </span>
                  </p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="boleto"
                    style={{
                      fontWeight: 'bold'
                    }}
                    href={this.state.resposta.transactions[0].details.boleto_url}
                  >
                    acessar link do boleto <i className="fa fa-barcode"></i>
                  </a>
                </React.Fragment>
              )}
              <div
                className="dados_customer"
                style={{
                  backgroundColor: this.props.values.step1_show === false ? '#f8f4fa' : '#ffffff'
                }}
              >
                <h2>
                  <img className="form_icon" src={customer_icon} alt="ícone cliente" /> seus dados
                </h2>
                <div className="dados_customer--full-description">
                  <p>
                    {this.props.values.customer.first_name} {this.props.values.customer.last_name} •&nbsp;
                    {this.props.values.customer.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}
                    <br />
                    {this.props.values.customer.email} •&nbsp;
                    {this.props.values?.customer?.phone.length === 10
                      ? this.props.values?.customer?.phone.replace('+55', '').replace(/(\d{2})(\d{4})/, '($1) $2-')
                      : this.props.values?.customer?.phone.replace('+55', '').replace(/(\d{2})(\d{5})/, '($1) $2-')}
                  </p>
                </div>
              </div>
              <div
                className="dados_entrega"
                id="dados_entrega"
                style={{
                  backgroundColor: this.props.values.step2_show === false ? '#f8f4fa' : '#ffffff',
                  alignItems: 'baseline'
                }}
              >
                <h2>
                  <img className="form_icon" src={house_icon} alt="ícone casa" /> endereço de entrega
                </h2>
                <div className="dados_entrega--info">
                  <p className="dados_entrega--full-description">
                    {this.props.values.shipping_info.address.street}
                    {this.props.values.shipping_info.address.street_number ? ', ' : ''}
                    {this.props.values.shipping_info.address.street_number}
                    {this.props.values.shipping_info.address.complement ? ', ' : ''}
                    {this.props.values.shipping_info.address.complement} <br />
                    {this.props.values.shipping_info.address.neighborhood
                      ? this.props.values.shipping_info.address.neighborhood + ' • '
                      : ''}
                    {this.props.values.shipping_info.address.city}/{this.props.values.shipping_info.address.state_short}{' '}
                    <br />
                    {this.props.values?.delivery_options[0]?.description} •&nbsp;
                    {this.props.values.totals.shipping - this.props.values.totals.shipping_discount === 0
                      ? 'grátis'
                      : 'R$ ' +
                        (this.props.values.totals.shipping - this.props.values.totals.shipping_discount)
                          .toFixed(2)
                          .replace('.', ',')}
                    •
                    {this.props.values?.delivery_options[0]?.estimate_business_days === 0
                      ? 'chega hoje! :)'
                      : `entrega em até ${this.props.values?.delivery_options[0]?.estimate_business_days} dias úteis`}
                  </p>
                </div>
              </div>
              <div className="dados_right" style={{ height: '100%' }}>
                <div className="dados_resumo" style={{ width: '100%' }}>
                  <div className="summary_header" onClick={() => this.onSummaryClick()}>
                    <div className="summary_header-title">
                      <p className="amount_produts"> {this.props.values.amountProducts} </p>
                      <img className="form_icon icon_bag" src={package_icon} alt="ícone cliente" />
                      <h2>resumo do pedido </h2>
                    </div>
                    <span>
                      <strong>
                        {' R$ '}
                        {this.props.values.totals
                          ? parseFloat(Math.round(this.props.values.totals.total * 100) / 100)
                              .toFixed(2)
                              .replace('.', ',')
                          : ''}
                      </strong>
                    </span>
                  </div>
                  {this.props.values.summaryVisible ? (
                    <React.Fragment>
                      <React.Fragment>
                        <div className="cart">
                          <Awards {...this.props} />
                          {this.props.values.products.map((product) => (
                            <React.Fragment key={product.title}>
                              {product.quantity > 0 ? (
                                <div className="cart__info" key={product.title}>
                                  <div className="cart__container--image">
                                    <img className="cart__image" src={product.image} alt="Imagem do produto" />
                                  </div>
                                  <div className="cart__container-title-quantity">
                                    <p className="cart__description"> {product?.title?.toLowerCase()} </p>
                                    <div className="cart__container--quantity-values">
                                      <div
                                        className="input-text qty text item__value"
                                        type="number"
                                        name="quantity_product"
                                        value={product.quantity}
                                        min="1"
                                        max={product.max_quantity}
                                        pattern="[0-6]*"
                                      >
                                        {product.quantity}
                                      </div>
                                      <div className="cart__container--values">
                                        {product.price > 0 ? (
                                          <React.Fragment>
                                            <div className="cart__item-price">
                                              {' R$ '}
                                              {parseFloat(Math.round(product.price * 100) / 100)
                                                .toFixed(2)
                                                .replace('.', ',')}
                                            </div>
                                            <div className="cart__total-price">
                                              {' R$ '}
                                              {parseFloat(Math.round(product.price * product.quantity * 100) / 100)
                                                .toFixed(2)
                                                .replace('.', ',')}
                                            </div>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <div className="cart__item-price price-item-free">brinde </div>
                                            <div className="cart__total-price">grátis </div>
                                          </React.Fragment>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <React.Fragment />
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      </React.Fragment>
                      {this.props.values.totals.total > 0 ? (
                        <React.Fragment>
                          <div className="dados_resumo-subtotal">
                            {Object.keys(this.props.values.totals).length !== 0 &&
                            this.props.values.totals.constructor === Object ? (
                              <React.Fragment>
                                <p> subtotal </p>
                                <p>
                                  R$
                                  {parseFloat(Math.round(this.props.values.totals.products * 100) / 100)
                                    .toFixed(2)
                                    .replace('.', ',')}
                                </p>
                              </React.Fragment>
                            ) : (
                              <React.Fragment />
                            )}
                          </div>
                          {this.props.values.totals.product_discount > 0 ? (
                            <div className="dados_resumo-desconto">
                              <p> descontos </p>
                              <p>
                                -R$
                                {parseFloat(Math.round(this.props.values.totals.product_discount * 100) / 100)
                                  .toFixed(2)
                                  .replace('.', ',')}
                              </p>
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="dados_resumo-frete">
                            {this.props.values.chosen_delivery_option !== null &&
                            this.props.values.delivery_options !== null ? (
                              <React.Fragment>
                                <p>
                                  <strong> frete </strong> para&nbsp;
                                  <a
                                    className="link_cep"
                                    href={
                                      this.props.values.step2_show || this.props.step3_show ? '#dados_entrega' : '#'
                                    }
                                    style={{
                                      cursor:
                                        this.props.values.step2_show || this.props.values.step3_show
                                          ? 'pointer'
                                          : 'not-allowed'
                                    }}
                                  >
                                    {this.props.values.shipping_info.address.zip_code.replace(
                                      /(\d{5})(\d{3})/,
                                      '$1-$2'
                                    )}
                                  </a>
                                  <span>
                                    {this.props.values?.delivery_options[0]?.estimate_business_days === 0
                                      ? 'chega hoje! :)'
                                      : `entrega em até ${this.props.values?.delivery_options[0]?.estimate_business_days} dias úteis`}
                                  </span>
                                </p>
                                <p>
                                  <strong>
                                    {this.props.values.totals.shipping - this.props.values.totals.shipping_discount ===
                                    0
                                      ? 'grátis'
                                      : 'R$ ' +
                                        (this.props.values.totals.shipping - this.props.values.totals.shipping_discount)
                                          .toFixed(2)
                                          .replace('.', ',')}
                                  </strong>
                                </p>
                              </React.Fragment>
                            ) : (
                              <React.Fragment />
                            )}
                          </div>
                          <div className="dados_resumo-total">
                            {this.props.values.chosen_delivery_option !== null ? (
                              <React.Fragment>
                                <p> total </p>
                                <p>
                                  R$
                                  {parseFloat(Math.round(this.props.values.totals.total * 100) / 100)
                                    .toFixed(2)
                                    .replace('.', ',')}
                                  {this.props.values.installment_chosen !== '' && (
                                    <span>em {this.props.values.installment_chosen}x no cartão </span>
                                  )}
                                  {this.props.values.payment_chosen === 'boleto' && <span> à vista no boleto </span>}
                                </p>
                              </React.Fragment>
                            ) : (
                              <React.Fragment />
                            )}
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment />
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <ErrorPhrase />
          )}
          {this.props.values.errorRetry ? (
            <React.Fragment></React.Fragment>
          ) : (
            <React.Fragment>
              <a className="button_return" href="https://www.sallve.com.br/collections/loja">
                loja da sallve
              </a>
            </React.Fragment>
          )}
        </div>
      </div>
    ) : (
      this.props.children
    );
  }
}
export const LogoContainer = () => {
  return (
    <div className="logo_container">
      <a
        href="https://www.sallve.com.br/"
        style={{
          lineHeight: '10px',
          height: '70%'
        }}
      >
        <img
          className="logo"
          src={logo}
          alt="Logo Sallve"
          style={{
            lineHeight: '10px',
            margin: '0'
          }}
        />
      </a>
      <img
        className="form_icon padlock_logo"
        src={padlock_logo}
        alt="Logo compra segura"
        style={{
          height: '15px',
          width: '13px'
        }}
      />
    </div>
  );
};

export const ErrorPhrase = () => {
  return (
    <div className="final-payment-error">
      <span role="img" aria-label="emoji chorando" style={{ fontSize: '54px' }}>
        😢
      </span>
      <h3>ops, não conseguimos finalizar a compra, tente novamente mais tarde.</h3>
    </div>
  );
};

export default ErrorHandler;
