import React, { Component } from 'react';
import { Field } from 'formik';
import CustomInputComponent from '../components/CustomInputComponent';
import validator from '../utils/validators';

import money_icon from '../img/pagamento.svg';
import padlock_logo from '../img/seguro.svg';
import { PaymentStatus } from '../utils/axiosRetry';

class PaymentPage extends Component {
  createMonths = (values) => {
    let currentDate = new Date();
    let months = [];
    months.push(
      <option key="month" value="" invalid={true.toString()} disabled>
        mês
      </option>
    );

    if (parseInt(values.card_expirationYear) === currentDate.getFullYear()) {
      for (let i = currentDate.getMonth() + 1; i <= 12; i++) {
        months.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
    } else {
      for (let i = 1; i <= 12; i++) {
        months.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
    }
    return months;
  };

  createYears = () => {
    let years = [];
    years.push(
      <option key="years" value="" invalid={true.toString()} disabled>
        ano
      </option>
    );
    for (let i = 2021; i <= 2050; i++) {
      years.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return years;
  };

  render() {
    const paymentStatus = this.props.values.paymentRequest.status;
    const disablePaymentBtn = PaymentStatus.started === paymentStatus || PaymentStatus.success === paymentStatus;

    return (
      <div
        className="dados_pagamento"
        style={{
          backgroundColor: this.props.values.step3_show === false ? '#f8f4fa' : '#ffffff'
        }}
      >
        <h2>
          <span>
            <img className="form_icon" src={money_icon} alt="ícone pagamento" /> <p>pagamento</p>
          </span>
          <span>
            <img className="padlock_logo" src={padlock_logo} alt="Logo compra segura" />
            <p className="compra-segura">compra segura</p>
          </span>
        </h2>
        <Field
          name="payment_fields"
          render={({ form }) =>
            form.values.step3_show ? (
              <React.Fragment>
                <div className="dados_pagamento_options">
                  <Field
                    name="payment_options"
                    render={({ form }) => (
                      <React.Fragment>
                        <label
                          className="credit_card-option"
                          style={{
                            backgroundColor: this.props.values.payment_chosen === 'credit_card' ? '#f9f4fa' : '#ffffff'
                          }}
                        >
                          <input
                            name="payment_options"
                            type="radio"
                            disabled={disablePaymentBtn}
                            id="credit_card"
                            checked={this.props.values.payment_chosen === 'credit_card' ? true : false}
                            className="payment_option"
                            onClick={() => {
                              form.setFieldValue('installment_chosen', '1');
                            }}
                            onChange={(event) => {
                              form.setFieldValue('payment_chosen', 'credit_card');
                            }}
                          />
                          cartão de crédito
                        </label>
                        <label
                          className="boleto-option"
                          style={{
                            backgroundColor: this.props.values.payment_chosen === 'boleto' ? '#f9f4fa' : '#ffffff'
                          }}
                        >
                          <input
                            name="payment_options"
                            type="radio"
                            id="boleto"
                            disabled={disablePaymentBtn}
                            checked={this.props.values.payment_chosen === 'boleto' ? true : false}
                            className="payment_option"
                            onClick={() => {
                              form.setFieldValue('installment_chosen', '');
                            }}
                            onChange={(event) => {
                              form.setFieldValue('payment_chosen', 'boleto');
                            }}
                          />
                          boleto
                        </label>
                      </React.Fragment>
                    )}
                  />
                </div>
                <Field
                  name="payment_container"
                  render={({ form }) =>
                    form.values.payment_chosen === 'credit_card' ? (
                      <div className="dados_pagamento_card">
                        <label className="flex:col has-validation has-legend no-margin">
                          <p className={`${form.errors?.card_number ? `invalid` : ''}`}>número do cartão</p>
                          <Field
                            type="text"
                            name="card_number"
                            placeholder="número do cartão"
                            component={CustomInputComponent}
                            validate={validator.validateCardNumber}
                            autoComplete="cc-number"
                            className={`${form.errors?.card_number ? `invalid` : ''}`}
                          />
                          {form.errors?.card_number && <i className="fa fa-exclamation-triangle" />}
                          <div className="legend invalid">{form.errors?.card_number}</div>
                        </label>
                        <label className="flex:col has-validation has-legend">
                          <p className={`${form.errors?.card_name ? `invalid` : ''}`}>nome impresso no cartão</p>
                          <Field
                            type="text"
                            name="card_name"
                            placeholder="nome impresso no cartão"
                            data-private="lipsum"
                            validate={validator.validateFullName}
                            autoComplete="cc-name"
                            className={`${form.errors?.card_name ? `invalid` : ''}`}
                          />
                          {form.errors?.card_name && <i className="fa fa-exclamation-triangle" />}
                          <div className="legend invalid">{form.errors?.card_name}</div>
                        </label>
                        <div className="dados_pagamento_card--sub-container-1st">
                          <label className="month_input has-validation">
                            <p
                              className={`${
                                form.errors?.card_expirationMonth ? `select_label invalid` : 'select_label'
                              }`}
                            >
                              mês validade
                            </p>
                            <Field
                              name="card_expirationMonth"
                              type="select"
                              autocomplete="cc-exp-month"
                              render={({ form }) => (
                                <select
                                  data-private="redact"
                                  className={`${form.errors?.card_expirationMonth ? `invalid` : ''}`}
                                  onChange={(e) => {
                                    form.setFieldError('card_expirationMonth', '');
                                    form.setFieldValue('card_expirationMonth', e.currentTarget.value);
                                  }}
                                  value={form.values.card_expirationMonth}
                                >
                                  {this.createMonths(form.values)}
                                </select>
                              )}
                            />
                          </label>
                          <label className="year_input has-validation">
                            <p
                              className={`${
                                form.errors?.card_expirationYear ? `select_label invalid` : 'select_label'
                              }`}
                            >
                              ano validade
                            </p>
                            <Field
                              name="card_expirationYear"
                              type="select"
                              autocomplete="cc-exp-year"
                              render={({ form }) => (
                                <select
                                  data-private="redact"
                                  className={`${form.errors?.card_expirationYear ? `invalid` : ''}`}
                                  onChange={(e) => {
                                    form.setFieldError('card_expirationYear', '');
                                    form.setFieldValue('card_expirationYear', e.currentTarget.value);
                                    this.createMonths(form.values);
                                  }}
                                  value={form.values.card_expirationYear}
                                >
                                  {this.createYears()}
                                </select>
                              )}
                            />
                          </label>

                          <label className="card_cvv has-validation">
                            <p className={`${form.errors?.card_cvv ? `invalid` : ''}`}>código</p>
                            <Field
                              type="text"
                              name="card_cvv"
                              placeholder="código de segurança (CVV)"
                              autoComplete="cc-csc"
                              maxLength="4"
                              component={CustomInputComponent}
                              validate={() =>
                                validator.validateCvvNumber(form.values.card_cvv, form.values.card_number)
                              }
                              className={`cvv_input ${form.errors?.card_cvv ? `invalid` : ''}`}
                            />
                            {form.errors?.card_cvv && <i className="fa fa-exclamation-triangle" />}
                          </label>
                        </div>
                        <div className="is-legend">
                          <div className="legend invalid">
                            {form.errors?.card_expirationMonth && <>{form.errors?.card_expirationMonth};&nbsp;&nbsp;</>}
                            {form.errors?.card_expirationYear && <>{form.errors?.card_expirationYear};&nbsp;&nbsp;</>}
                            {form.errors?.card_cvv && <>{form.errors?.card_cvv}</>}
                          </div>
                        </div>
                        <div className="dados_pagamento_card--sub-container-2nd">
                          <label className="flex:col has-validation has-legend">
                            <p className={`${form.errors?.card_document ? `invalid` : ''}`}>CPF do titular</p>
                            <Field
                              type="text"
                              name="card_document"
                              placeholder="CPF"
                              component={CustomInputComponent}
                              validate={validator.validateCPF}
                              className={`${form.errors?.card_document ? `invalid` : ''}`}
                            />
                            {form.errors?.card_document && <i className="fa fa-exclamation-triangle" />}
                            <div className="legend invalid">{form.errors?.card_document}</div>
                          </label>
                          <label className="flex:col has-validation has-legend">
                            <p className={`${form.errors?.card_birthday ? `invalid` : ''}`}>data de nascimento</p>
                            <Field
                              type="text"
                              name="card_birthday"
                              placeholder="data de nascimento"
                              component={CustomInputComponent}
                              validate={validator.validateBirth}
                              className={`${form.errors?.card_birthday ? `card_birthday invalid` : 'card_birthday'}`}
                            />
                            {form.errors?.card_birthday && <i className="fa fa-exclamation-triangle" />}
                            <div className="legend invalid">{form.errors?.card_birthday}</div>
                          </label>
                        </div>
                        <label className="dados_pagamento_card--installments">
                          <p className="select_label">parcelas</p>
                          <Field
                            name="installment_chosen"
                            placeholder="parcelas"
                            render={() => {
                              return (
                                <select
                                  onChange={(e) => form.setFieldValue('installment_chosen', e.currentTarget.value)}
                                  value={form.values.installment_chosen}
                                >
                                  {form.values.payment_options[1].installments.map((installment) => (
                                    <option key={installment.installment} value={installment.installment}>
                                      {installment.installment} {installment.installment === 1 ? 'parcela' : 'parcelas'}
                                      &nbsp;de R$
                                      {parseFloat(Math.round(installment.value * 100) / 100)
                                        .toFixed(2)
                                        .replace('.', ',')}
                                      &nbsp;(sem juros)
                                    </option>
                                  ))}
                                </select>
                              );
                            }}
                          />
                        </label>
                      </div>
                    ) : (
                      <React.Fragment>
                        <ul className="boleto-text">
                          <li>
                            O boleto vence em <strong>3 dias</strong> e será exibido após a finalização do pedido
                          </li>
                          <li>
                            Você pode <strong>copiar</strong> o número do código de barras para pagamento pela internet
                            ou <strong>imprimir</strong> o boleto para pagamento em agências bancárias
                          </li>
                          <li>
                            O <strong>prazo de entrega</strong> começa a contar a partir da confirmação do pagamento do
                            boleto
                          </li>
                        </ul>
                      </React.Fragment>
                    )
                  }
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p className="dados_aguardando">
                  aguardando o preenchimento&nbsp;
                  {form.values.step2_show ? (
                    <React.Fragment>
                      do <b> endereço</b>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      de <b>seus dados</b>
                    </React.Fragment>
                  )}
                </p>
              </React.Fragment>
            )
          }
        />
      </div>
    );
  }
}

export default PaymentPage;
