import React, { Component } from 'react';
import { Field } from 'formik';
import LogRocket from 'logrocket';

import CustomInputComponent from '../components/CustomInputComponent';
import checkPack from '../utils/checkPack';
import validator from '../utils/validators';
import { IS_DEV } from '../api';

import customer_icon from '../img/customer-icon.svg';

class CustomerPage extends Component {
  constructor(props) {
    super(props);
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.handleTab);
  }

  UNSAFE_componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleTab);
  };

  handleClickOutside = (event) => {
    if (this.pop) {
      if (
        !(this.pop.props.className === event.target.className) &&
        this.props.touched &&
        this.props.touched.customer &&
        this.props.touched.customer.email === true
      ) {
        // click outside of email field after touch it
        this.props.setFieldTouched('customer.email', false);
        checkPack.checkEmail(this.props, this.props.setFieldValue);
      } else if (this.pop.props.className === event.target.className) {
        // email field is touched
        this.props.setFieldTouched('customer.email');
      }
    }
  };

  handleTab = (event) => {
    if (event.keyCode === 9 && event.target.className === 'customer_email') {
      checkPack.checkEmail(this.props, this.props.setFieldValue);
    }
  };

  pressedEnter = (event) => {
    let code = event.keyCode === 13 || event.which;
    if (code === 13) {
      event.preventDefault();
      checkPack.checkCustomer(this.props, this.props.setFieldValue);
    }
  };

  superBuyer = (awards_products) => {
    if (document.getElementById('superBuyer') !== null) {
      for (var i = 0; i < awards_products.length; i++) {
        if (
          awards_products[i].customer_tag &&
          awards_products[i].customer_tag.includes('superBuyer') &&
          awards_products[i].valid_customer_tag === true &&
          awards_products[i].surprise !== true
        ) {
          document.getElementById('superBuyer').style.display = 'block';
          return null;
        }
      }
      document.getElementById('superBuyer').style.display = 'none';
    }
  };

  render() {
    return (
      <>
        <div
          className="dados_customer"
          style={{
            backgroundColor: this.props.values.step1_show === false ? '#f8f4fa' : '#ffffff'
          }}
        >
          <h2>
            <img className="form_icon" src={customer_icon} alt="ícone cliente" /> seus dados
          </h2>
          <Field
            name="shipping_fields"
            render={({ form }) =>
              form.values.step1_show ? (
                <React.Fragment>
                  <label>
                    <p>seu email</p>
                    <Field
                      className="customer_email"
                      type="email"
                      name="customer.email"
                      id="customer_email"
                      placeholder="seu email"
                      validate={validator.validateEmail}
                      ref={(node) => {
                        this.pop = node;
                      }}
                      onKeyUp={(event) => {
                        let afterEnterPressed = event.keyCode === 13 || event.which; //check if enter was pressed
                        if (form.values.email_suggestion !== '' && !afterEnterPressed) {
                          form.setFieldValue('email_suggestion', '');
                        }
                      }}
                      onKeyPress={(event) => this.pressedEnter(event)}
                      style={{
                        color: form.errors?.customer?.email ? '#C31E1E' : '#333333',
                        border: form.errors?.customer?.email ? '1px solid #C31E1E' : '1px solid #999999'
                      }}
                    />
                    {form.errors?.customer?.email && <i className="fa fa-exclamation-triangle" />}
                  </label>
                  {form.values.email_suggestion && (
                    <React.Fragment>
                      <p className="customer__email__typo">
                        o email correto seria:&nbsp;
                        <span
                          className="customer__email__typo--underline"
                          onClick={() => {
                            form.setFieldError('customer.email', undefined);
                            form.setFieldValue('customer.email', form.values.email_suggestion);
                            form.setFieldValue('email_suggestion', '');
                          }}
                        >
                          {form.values.email_suggestion}
                        </span>
                        ?
                        <span
                          className="customer__email__typo--answer"
                          onClick={() => {
                            form.setFieldValue('customer.email', form.values.email_suggestion);
                            form.setFieldError('customer.email', undefined);
                            form.setFieldValue('email_suggestion', '');
                          }}
                        >
                          sim
                        </span>
                        <span
                          className="customer__email__typo--answer"
                          onClick={() => {
                            form.setFieldValue('email_suggestion', '');
                          }}
                        >
                          não
                        </span>
                      </p>
                    </React.Fragment>
                  )}
                  <div className="dados_customer-names">
                    <label>
                      <p>seu primeiro nome</p>
                      <Field
                        type="text"
                        name="customer.first_name"
                        placeholder="nome"
                        validate={validator.validateName}
                        onKeyPress={(event) => this.pressedEnter(event)}
                        autoComplete="given-name"
                        value={this.props.values.customer.first_name.replace('  ', ' ')}
                        style={{
                          color: form.errors?.customer?.first_name ? '#C31E1E' : '#333333',
                          border: form.errors?.customer?.first_name ? '1px solid #C31E1E' : '1px solid #999999'
                        }}
                      />
                      {form.errors?.customer?.first_name && <i className="fa fa-exclamation-triangle" />}
                    </label>
                    <label>
                      <p>seu sobrenome</p>
                      <Field
                        type="text"
                        name="customer.last_name"
                        placeholder="sobrenome"
                        validate={validator.validateLastName}
                        onKeyPress={(event) => this.pressedEnter(event)}
                        autoComplete="family-name"
                        value={this.props.values.customer.last_name.replace('  ', ' ')}
                        style={{
                          color: form.errors?.customer?.last_name ? '#C31E1E' : '#333333',
                          border: form.errors?.customer?.last_name ? '1px solid #C31E1E' : '1px solid #999999'
                        }}
                      />
                      {form.errors?.customer?.last_name && <i className="fa fa-exclamation-triangle" />}
                    </label>
                  </div>
                  <div className="dados_customer-info">
                    <label>
                      <p>seu celular com DDD</p>
                      <Field
                        type="text"
                        className="customer--phone"
                        id="customer--phone"
                        name="customer.phone"
                        placeholder="celular / whatsapp"
                        validate={validator.validatePhone}
                        onKeyPress={(event) => this.pressedEnter(event)}
                        maxLength="15"
                        component={CustomInputComponent}
                        autoComplete="tel-national"
                        style={{
                          color: form.errors?.customer?.phone ? '#C31E1E' : '#333333',
                          border: form.errors?.customer?.phone ? '1px solid #C31E1E' : '1px solid #999999'
                        }}
                      />
                      {form.errors?.customer?.phone && <i className="fa fa-exclamation-triangle" />}
                    </label>
                    <label>
                      <p>seu CPF</p>
                      <Field
                        type="text"
                        name="customer.document"
                        placeholder="CPF"
                        component={CustomInputComponent}
                        validate={validator.validateCPF}
                        onKeyPress={(event) => this.pressedEnter(event)}
                        style={{
                          color: form.errors?.customer?.document ? '#C31E1E' : '#333333',
                          border: form.errors?.customer?.document ? '1px solid #C31E1E' : '1px solid #999999'
                        }}
                      />
                      {form.errors?.customer?.document && <i className="fa fa-exclamation-triangle" />}
                    </label>
                    {this.props.values.infoCookie.shipping_info &&
                    this.props.values.infoCookie.shipping_info.address.zip_code ? (
                      <React.Fragment></React.Fragment>
                    ) : (
                      <Field
                        className="dados_entrega--CEP"
                        type="text"
                        name="shipping_info.address.zip_code"
                        placeholder="CEP"
                        component={CustomInputComponent}
                        validate={validator.validateCEP}
                        autoComplete="shipping postal-code"
                        inputMode="numeric"
                        style={{
                          left: '-9999px',
                          position: 'absolute'
                        }}
                      />
                    )}
                  </div>
                  {form.values.fixFields !== '' && (
                    <div className="fix-payments">
                      <h2 className="fix-fields">{form.values.fixFields}</h2>
                    </div>
                  )}
                  <label className="customer_email-marketing">
                    <Field
                      className="regular-checkbox"
                      type="checkbox"
                      name="customer.accept_whatsapp"
                      placeholder="whatsapp"
                      onChange={(event) => form.setFieldValue('customer.accept_whatsapp', event.target.checked)}
                      checked={form.values.customer.accept_whatsapp}
                    />
                    <div className="customer-mkt-div">quero acompanhar meu pedido por WhatsApp</div>
                  </label>
                  <label className="customer_email-marketing">
                    <Field
                      className="regular-checkbox"
                      type="checkbox"
                      name="customer.accept_marketing"
                      placeholder="email marketing"
                      onChange={(event) => form.setFieldValue('customer.accept_marketing', event.target.checked)}
                      checked={form.values.customer.accept_marketing}
                    />
                    <div className="customer-mkt-div">
                      <span className="short-text">quero receber novidades e dicas de skincare da Sallve</span>
                      <span className="short-text">(prometemos enviar só coisa boa) ;)</span>
                      <span className="long-text">
                        quero receber novidades e dicas de skincare da Sallve (prometemos enviar só coisa boa) ;)
                      </span>
                    </div>
                  </label>
                  <Field
                    name="button_save_customer"
                    render={({ form }) => (
                      <button
                        className="dados_customer_button"
                        disabled={!!form.errors?.customer?.email || !!form.errors?.customer?.phone}
                        onClick={() => {
                          if (!IS_DEV) {
                            LogRocket.identify(form.values?.id, {
                              name: `${form.values?.customer?.first_name} ${form.values?.customer?.last_name}`,
                              email: form.values?.customer?.email
                            });
                          }

                          checkPack.checkCustomer(form, form.setFieldValue);
                        }}
                      >
                        <strong>salvar dados</strong>
                      </button>
                    )}
                  />
                </React.Fragment>
              ) : (
                <>
                  <div className="dados_customer--full-description">
                    <p>
                      {form.values.customer.first_name} {form.values.customer.last_name} •&nbsp;
                      <span data-private="lipsum">
                        {form.values.customer.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}
                      </span>
                      <br />
                      {form.values.customer.email} •&nbsp;
                      {form.values.customer?.phone.length === 10
                        ? form.values.customer?.phone.replace('+55', '').replace(/(\d{2})(\d{4})/, '($1) $2-')
                        : form.values.customer?.phone.replace('+55', '').replace(/(\d{2})(\d{5})/, '($1) $2-')}
                    </p>
                    <a
                      className="button__change"
                      href="#"
                      onClick={() => {
                        form.setFieldValue('step1_show', true);
                        form.setFieldValue('step2_show', false);
                        form.setFieldValue('step3_show', false);
                        form.setFieldValue('fixFields', '');
                      }}
                    >
                      alterar
                    </a>
                  </div>
                  {this.superBuyer(form.values.awards.products)}
                </>
              )
            }
          />
        </div>
        <div className="super_buyer super_buyer_div" style={{ display: 'none' }} id="superBuyer">
          <span className="super_buyer super_buyer_text">★ você é uma super compradora</span>
        </div>
      </>
    );
  }
}

export default CustomerPage;
