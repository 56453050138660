const siteTracking = {
  checkoutStart: function (cart) {
    if (window.dataLayer) {
      let products_data = [];
      let products_name = [];
      let products_id = [];
      let products_price = [];
      let products_quantity = [];

      for (let i = 0; i < cart.products.length; i++) {
        products_data.push({
          name: cart.products[i].title,
          id: cart.products[i].external_sku,
          price: parseFloat(Math.round(cart.products[i].price * 100) / 100)
            .toFixed(2)
            .toString(),
          brand: 'Sallve',
          category: 'Mercadoria para Revenda',
          quantity: cart.products[i].quantity,
          variant: cart.products[i].sku
        });
      }
      for (let i = 0; i < cart.awards.products.length; i++) {
        products_data.push({
          name: cart.awards.products[i].product.title,
          id: cart.awards.products[i].product.external_sku,
          price: '0.0',
          brand: 'Sallve',
          category: 'Outras',
          quantity: cart.awards.products[i].product.quantity,
          variant: cart.awards.products[i].product.sku
        });
      }
      for (let i = 0; i < cart.products.length; i++) {
        products_name.push(cart.products[i].title);
        products_id.push(cart.products[i].sku);
        products_price.push(
          parseFloat(Math.round(cart.products[i].price * 100) / 100)
            .toFixed(2)
            .toString()
        );
        products_quantity.push(cart.products[i].quantity);
      }

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1
            },
            products: products_data
          }
        }
      });

      window.fbq('track', 'InitiateCheckout', {
        value: cart.totals.total,
        currency: 'BRL',
        content_id: products_id,
        content_name: products_name,
        price: products_price,
        quantity: products_quantity
      });
    }

    window.ga('create', 'UA-127195310-3', 'auto');
    window.ga('set', {
      page: '/checkout/contact_information',
      title: 'Checkout - Dados do usuário'
    });
    window.ga('send', 'pageview');
  },

  checkoutFinish: function (cart) {
    if (window.dataLayer) {
      let products_data = [];
      for (let i = 0; i < cart.products.length; i++) {
        products_data.push({
          name: cart.products[i].title,
          id: cart.products[i].external_sku,
          price: parseFloat(Math.round(cart.products[i].price * 100) / 100)
            .toFixed(2)
            .toString(),
          brand: 'Sallve',
          category: 'Mercadoria para Revenda',
          quantity: cart.products[i].quantity,
          variant: cart.products[i].sku,
          coupon: ''
        });
      }

      for (let i = 0; i < cart.awards.products.length; i++) {
        products_data.push({
          name: cart.awards.products[i].product.title,
          id: cart.awards.products[i].product.external_sku,
          price: '0.0',
          brand: 'Sallve',
          category: 'Outras',
          quantity: cart.awards.products[i].product.quantity,
          variant: cart.awards.products[i].product.sku
        });
      }
      window.dataLayer.push({
        event: 'checkout_finish',
        ecommerce: {
          purchase: {
            currencyCode: 'BRL',
            actionField: {
              id: cart.id,
              affiliation: 'Sallve Store',
              revenue: parseFloat(Math.round(cart.totals.total * 100) / 100)
                .toFixed(2)
                .toString(),
              tax: '0',
              shipping: parseFloat(Math.round(cart.totals.shipping * 100) / 100)
                .toFixed(2)
                .toString(),
              coupon: cart.discount_code.valid ? cart.discount_code.code.toUpperCase() : ''
            },
            products: products_data
          }
        }
      });
    }

    let products_data = [];

    for (let i = 0; i < cart.products.length; i++) {
      products_data.push({
        id: cart.products[i].sku,
        quantity: cart.products[i].quantity
      });
    }

    for (let i = 0; i < cart.awards.products.length; i++) {
      products_data.push({
        id: cart.awards.products[i].product.sku,
        quantity: cart.awards.products[i].product.quantity
      });
    }

    window.fbq('track', 'Purchase', {
      value: cart.totals.total,
      currency: 'BRL',
      contents: products_data,
      content_type: 'product',
      product_catalog_id: '756478868232292'
    });
  },

  saveAddress: function () {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'checkoutOption',
        ecommerce: {
          checkout: {
            actionField: {
              step: 3
            }
          }
        }
      });
    }

    window.ga('create', 'UA-127195310-3', 'auto');
    window.ga('set', {
      page: '/checkout/payment',
      title: 'Checkout - Dados de Pagamento'
    });
    window.ga('send', 'pageview');
  },
  saveUser: function () {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'checkoutOption',
        ecommerce: {
          checkout: {
            actionField: {
              step: 2
            }
          }
        }
      });
    }
    window.fbq('track', 'Contact');

    window.ga('create', 'UA-127195310-3', 'auto');
    window.ga('set', {
      page: '/checkout/shipping',
      title: 'Checkout - Entrega'
    });
    window.ga('send', 'pageview');
  },

  sendVirtualPageView: function (cart) {
    let url = '';
    for (let i = 0; i < cart.products.length; i++) {
      url += 's' + cart.products[i].sku + 'q' + cart.products[i].quantity + '/';
    }
    window.ga('create', 'UA-127195310-3', 'auto');
    window.ga('set', {
      page: '/' + url,
      title: 'Virtual Page View - Products'
    });
    window.ga('send', 'pageview');

    window.fbq('trackCustom', 'VirtualPageView', {
      url: url
    });
  },

  trackingAddItem: function (product) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'BRL',
          add: {
            products: [
              {
                name: product.title,
                id: product.external_sku,
                price: parseFloat(Math.round(product.price * 100) / 100)
                  .toFixed(2)
                  .toString(),
                brand: 'Sallve',
                category: 'Mercadoria para Revenda',
                quantity: 1,
                variant: product.sku
              }
            ]
          }
        }
      });
    }

    window.fbq('track', 'AddToCart', {
      value: product.price,
      currency: 'BRL',
      content_type: 'product',
      product_catalog_id: '756478868232292',
      contents: [
        {
          id: product.sku,
          quantity: 1
        }
      ]
    });
  },

  trackingRemoveItem: function (product) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'removeFromCart',
        ecommerce: {
          currencyCode: 'BRL',
          remove: {
            products: [
              {
                name: product.title,
                id: product.external_sku,
                price: parseFloat(Math.round(product.price * 100) / 100)
                  .toFixed(2)
                  .toString(),
                brand: 'Sallve',
                category: 'Mercadoria para Revenda',
                quantity: 1,
                variant: product.sku
              }
            ]
          }
        }
      });
    }
  }
};

export default siteTracking;
