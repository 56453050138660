import React, { Component } from 'react';
import loading from '../img/loading.gif';
import ReactTextRotator from 'react-text-rotator';

class Loading extends Component {
  getSentences = () => {
    let content = [
      {
        text: 'verificando as informações',
        className: 'classA',
        animation: 'fade'
      },
      {
        text: 'só um segundinho tá?',
        className: 'classB',
        animation: 'fade'
      },
      {
        text: 'tá quase...',
        className: 'classC',
        animation: 'fade'
      },
      {
        text: 'hidratando os servidores',
        className: 'classD',
        animation: 'fade'
      }
    ];
    return content;
  };

  render() {
    return (
      <div className="container-loading">
        <img className="loading-gif" src={loading} alt="Logo Sallve" />
        <div className="text-rotator">
          <ReactTextRotator content={this.getSentences()} time={4000} startDelay={500} />
        </div>
      </div>
    );
  }
}

export default Loading;
