import axios from 'axios';
import cartCookies from '../utils/cartCookies';
import { fibonacci } from '../helpers/math';
import { BACKEND_URL } from '../api';
import { ERRORS, errorType } from '../components/Modal';

const MAX_RETRY = 30;

export const PaymentStatus = Object.freeze({ not_started: 0, started: 1, success: 2, error: 3 });

function retryToMyService(cart) {
  let errorString = cart?.id?.toString();
  window.ga('create', 'UA-127195310-3', 'auto');
  window.ga('set', {
    page: '/timeout',
    title: errorString
  });
  window.ga('send', 'pageview');
}

export function errorHandler({ errors, values, setFieldValue }) {
  setFieldValue('paymentRequest', { status: PaymentStatus.error, errors });
  retryToMyService(values);
  if (errorType(ERRORS.FINAL, errors)) {
    cartCookies.cleanCookie();
  }
}

export function successHandler(result, setFieldValue) {
  cartCookies.cleanCookie();
  setFieldValue('paymentRequest', { status: PaymentStatus.success });
  setTimeout(() => (window.location.href = result.data.redirect), 2000);
}

function axiosRetry(cart, setFieldValue, result) {
  const retryTimeout = [...fibonacci(MAX_RETRY + 1)].map((x) => x * 500);
  let currentRetry = 0;

  function sendWithRetry(cart) {
    setFieldValue('isLoadingForm', true);
    axios
      .get(`${BACKEND_URL}/order/by-card-id?cart_id=${cart.id}`)
      .then((result) => {
        successHandler(result, setFieldValue);
      })
      .catch((error) => {
        if (currentRetry < MAX_RETRY) {
          currentRetry += 1;
          setTimeout(() => sendWithRetry(cart), retryTimeout[currentRetry]);
        } else {
          errorHandler({ error: error, cart: cart, setFieldValue: setFieldValue });
        }
      });
  }

  axios.interceptors.response.use(
    function (response) {
      if (!response.data?.external_thank_you_page?.length) {
        throw new axios.Cancel('Operation canceled by the user.');
      } else {
        return response;
      }
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  sendWithRetry(cart);
}

export default axiosRetry;
