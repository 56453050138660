/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react';

import Barcode from '-!svg-react-loader!../img/barcode.svg';
import Card from '-!svg-react-loader!../img/card.svg';
import Pix from '-!svg-react-loader!../img/pix.svg';
import CustomerIcon from '-!svg-react-loader!../img/customer-icon.svg';
import Entrega from '-!svg-react-loader!../img/entrega.svg';
import Pagamento from '-!svg-react-loader!../img/pagamento.svg';
import Sacola from '-!svg-react-loader!../img/sacola.svg';
import Seguro from '-!svg-react-loader!../img/seguro.svg';
import Check from '-!svg-react-loader!../img/check-mark-5.svg';
import Cross from '-!svg-react-loader!../img/x-mark-5.svg';

const pickIcon = (name) => {
  switch (name) {
    case 'barcode':
      return Barcode;
    case 'card':
      return Card;
    case 'pix':
      return Pix;
    case 'customer-icon':
      return CustomerIcon;
    case 'entrega':
      return Entrega;
    case 'pagamento':
      return Pagamento;
    case 'sacola':
      return Sacola;
    case 'seguro':
      return Seguro;
    case 'check':
      return Check;
    case 'cross':
      return Cross;
    default:
      throw new Error('no SVG for: ' + name);
  }
};

const Icon = ({ name, fill = '#333' }) => {
  const SVG = pickIcon(name);
  return <SVG fill={fill} />;
};

export default Icon;
