import React, { Component } from 'react';
import { Field } from 'formik';
import { captureException } from '@sentry/react';
import axios from 'axios';

import cart from '../utils/cartPost';
import cartCookies from '../utils/cartCookies';
import setFields from '../utils/setFields';
import Awards from '../components/Awards';
import package_icon from '../img/sacola.svg';
import siteTracking from '../utils/siteTracking';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

class SummaryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updating: ''
    };

    setFields('getting_info_utm', this.props.setFieldValue, cartCookies.getUTM());

    if (this.props.values.infoCookie) {
      setFields('getting_info_cookies', this.props.setFieldValue, this.props.values.infoCookie);
      setFields('actual_step', this.props.setFieldValue, this.props);
    }
    if (this.props.values.infoCookie.has_success_order) {
      this.props.setFieldValue('isCartEmpty', true);
      cartCookies.cleanCookie();
    }

    if (this.props.values.infoCookie.products.length === 0) {
      this.props.setFieldValue('isCartEmpty', true);
    }

    if (window.innerWidth < 600) {
      this.props.setFieldValue('summaryVisible', false);
    }
    this.removeOutOfStock();
    this.amountProducts();
  }

  addingItems = (product, quantity) => {
    let productList = this.props.values.products;
    let productPosition = productList.findIndex((e) => e.sku === product.sku);
    let previousQuantity;
    if (quantity === 0) {
      productList.splice(productPosition, 1);
    } else {
      previousQuantity = productList[productPosition].quantity;
      productList[productPosition].quantity = quantity;
    }

    axios
      .post(`${BACKEND_URL}/cart/`, {
        id: this.props.values.id,
        products: productList,
        discount_code: { code: this.props.values.discount_code.code }
      })
      .then((response) => {
        if (!response.data.discount_code.valid) {
          this.props.setFieldValue('discount_code', { code: '', valid: null, message: '' });
        }
        this.amountProducts();
        this.props.setFieldValue('delivery_options', response.data.delivery_options);
        this.props.setFieldValue('payment_options', response.data.payment_options);
        this.props.setFieldValue('awards', response.data.awards);
        this.props.setFieldValue('totals', response.data.totals);
        this.checkDifference(product, quantity, previousQuantity);
        this.removeOutOfStock();
        cartCookies.setSallveCart(response.data);
      })
      .catch((error) => {
        captureException(error);
      });
  };

  amountProducts = () => {
    let total = 0;
    let array = this.props.values.products;
    for (let i = 0; i < array.length; i++) {
      total += array[i].quantity;
    }
    this.props.setFieldValue('amountProducts', total);
    if (this.props.values.amountProducts === 0) {
      this.props.setFieldValue('isCartEmpty', true);
    }
  };

  checkDifference = (product, quantity, previousQuantity) => {
    let diff = quantity - previousQuantity;
    if (diff > 0) {
      siteTracking.trackingAddItem(product);
    } else {
      siteTracking.trackingRemoveItem(product);
    }
  };

  onSummaryClick = (event) => {
    if (window.innerWidth <= 600) {
      this.props.setFieldValue('summaryVisible', !this.props.values.summaryVisible);
      this.setState((prevState) => ({
        summaryVisible: !prevState.summaryVisible
      }));
    }
  };

  removeOutOfStock = () => {
    let tmp_products = [];
    let has_out_of_stock_products = false;
    for (let i = 0; i < this.props.values.products.length; i++) {
      if (this.props.values.products[i].has_stock) {
        tmp_products.push(this.props.values.products[i]);
      } else {
        has_out_of_stock_products = true;
      }
    }
    if (has_out_of_stock_products) {
      this.props.setFieldValue('products', tmp_products);
    }
  };

  onChange = (value) => {
    const { updating } = this.state;
    if (updating) {
      clearTimeout(updating);
    }
    const timeout = setTimeout(() => cart.cartPost(this.props.values, 'product', this.props.setFieldValue), 0);
    this.setState({ updating: timeout });
  };

  render() {
    return (
      <div className="dados_resumo">
        <div className="summary_header" onClick={() => this.onSummaryClick()}>
          <div className="summary_header-title">
            <p className="amount_produts"> {this.props.values.amountProducts} </p>
            <img className="form_icon icon_bag" src={package_icon} alt="ícone cliente" />
            <h2>
              resumo do pedido
              {this.props.values.summaryVisible ? (
                <i className="fa fa-chevron-up"> </i>
              ) : (
                <i className="fa fa-chevron-down"> </i>
              )}
            </h2>
          </div>
          <span>
            <strong>
              {' R$ '}
              {this.props.values.totals
                ? parseFloat(Math.round(this.props.values.totals.total * 100) / 100)
                    .toFixed(2)
                    .replace('.', ',')
                : ''}
            </strong>
          </span>
        </div>
        {this.props.values.summaryVisible ? (
          <React.Fragment>
            <Field
              name="summary_fields"
              render={({ form }) =>
                form.values.products ? (
                  <React.Fragment>
                    <div className="cart">
                      <Awards {...this.props} />
                      {form.values.products.map((product) => (
                        <React.Fragment key={product?.title}>
                          {product.quantity > 0 ? (
                            <div className="cart__info" key={product.title}>
                              <div className="cart__container--image">
                                <img className="cart__image" src={product.image} alt="Imagem do produto" />
                              </div>
                              <div className="cart__container-title-quantity">
                                <p className="cart__description">
                                  {product?.title?.toLowerCase()}
                                  <a // eslint-disable-line
                                    href="#"
                                    className="cart__remove"
                                    aria-label="Remover este produto"
                                    onClick={() => {
                                      this.props.setFieldValue(
                                        'amountProducts',
                                        this.props.values.amountProducts - product.quantity
                                      );
                                      this.addingItems(product, 0);
                                    }}
                                  >
                                    x
                                  </a>
                                </p>
                                <div className="cart__container--quantity-values">
                                  <div className="cart__container--quantity">
                                    <div className="cart__quantity quantity">
                                      {product.quantity > 1 && (
                                        <span
                                          className="qty-minus"
                                          onClick={() => {
                                            this.addingItems(product, product.quantity - 1);
                                            this.props.setFieldValue(
                                              'amountProducts',
                                              this.props.values.amountProducts - 1
                                            );
                                          }}
                                        />
                                      )}
                                      <Field
                                        className="input-text qty text item__value"
                                        type="number"
                                        name="quantity_product"
                                        value={product.quantity}
                                        min="1"
                                        max={product.max_quantity}
                                        pattern="[0-6]*"
                                      />
                                      {product.quantity < product.max_quantity && (
                                        <span
                                          className="qty-plus"
                                          onClick={() => {
                                            this.addingItems(product, product.quantity + 1);
                                            this.props.setFieldValue(
                                              'amountProducts',
                                              this.props.values.amountProducts + 1
                                            );
                                          }}
                                        >
                                          +
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="cart__container--values">
                                    {product.price > 0 ? (
                                      <React.Fragment>
                                        <div className="cart__item-price">
                                          {' R$ '}
                                          {parseFloat(Math.round(product.price * 100) / 100)
                                            .toFixed(2)
                                            .replace('.', ',')}
                                        </div>
                                        <div className="cart__total-price">
                                          {' R$ '}
                                          {parseFloat(Math.round(product.price * product.quantity * 100) / 100)
                                            .toFixed(2)
                                            .replace('.', ',')}
                                        </div>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        <div className="cart__item-price price-item-free">brinde </div>
                                        <div className="cart__total-price">grátis </div>
                                      </React.Fragment>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="cart">
                    <p> Não existem itens no seu carrinho: ( </p>
                  </div>
                )
              }
            />
            <div className="cart__message">
              {this.props.values.totals.products < 109 && <p>ganhe frete grátis em compras acima de R$ 109</p>}

              {this.props.values.totals.products > 109 && this.props.values.totals.products < 149 && (
                <p>ganhe um mimo em compras acima de R$ 149 💝</p>
              )}

              {this.props.values.totals.products > 149 && <p>uau! sua pele agradece!</p>}
            </div>
            <div className="dados_resumo-code">
              <p> cupom: </p>
              {this.props.values.show_cupom_field ? (
                <React.Fragment>
                  <Field
                    type="text"
                    name="code"
                    className="code-input"
                    placeholder="código secreto"
                    maxLength={25}
                    onKeyPress={(event) => {
                      let code = event.keyCode === 13 || event.which;
                      if (code === 13) {
                        event.preventDefault();
                        cart.cartPost(this.props.values, 'code', this.props.setFieldValue);
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="button_code"
                    onClick={(event) => {
                      event.stopPropagation();
                      event.nativeEvent.stopImmediatePropagation();
                      cart.cartPost(this.props.values, 'code', this.props.setFieldValue);
                    }}
                  >
                    OK
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <a // eslint-disable-line
                    className={
                      this.props.values.discount_code.code !== '' && this.props.values.discount_code.valid
                        ? 'cupom_code_link bold-text'
                        : 'cupom_code_link'
                    }
                    onClick={() => this.props.setFieldValue('show_cupom_field', true)}
                  >
                    {this.props.values.discount_code.code !== '' && this.props.values.discount_code.valid !== false
                      ? this.props.values.discount_code.code
                      : 'adicionar'}
                  </a>
                  <span
                    className="cupom_complement"
                    style={{
                      color:
                        this.props.values.discount_code.valid !== false || this.props.values.discount_code.code === ''
                          ? '#333333'
                          : '#FE5131'
                    }}
                  >
                    {this.props.values.discount_code.code === '' ? (
                      <React.Fragment />
                    ) : (
                      <React.Fragment>
                        {this.props.values.discount_code.valid === true ? (
                          this.props.values.discount_code.code + ' é um cupom válido ;)'
                        ) : (
                          <React.Fragment>
                            {this.props.values.discount_code.valid === null &&
                            this.props.values.discount_code.code !== '' ? (
                              'será validado após inserir seus dados'
                            ) : (
                              <React.Fragment>
                                {this.props.values.discount_code.valid === false &&
                                this.props.values.discount_code.message
                                  ? this.props.values.discount_code.code + ' ' + this.props.values.discount_code.message
                                  : this.props.values.discount_code.code + ' não é um cupom válido :('}
                              </React.Fragment>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </span>
                </React.Fragment>
              )}
            </div>
            {this.props.values.totals.total > 0 ? (
              <React.Fragment>
                <div className="dados_resumo-subtotal">
                  {Object.keys(this.props.values.totals).length !== 0 &&
                  this.props.values.totals.constructor === Object ? (
                    <React.Fragment>
                      <p> subtotal </p>
                      <p>
                        R$
                        {parseFloat(Math.round(this.props.values.totals.products * 100) / 100)
                          .toFixed(2)
                          .replace('.', ',')}
                      </p>
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
                {this.props.values.totals.product_discount > 0 ? (
                  <div className="dados_resumo-desconto">
                    <p> descontos </p>
                    <p>
                      -R$
                      {parseFloat(Math.round(this.props.values.totals.product_discount * 100) / 100)
                        .toFixed(2)
                        .replace('.', ',')}
                    </p>
                  </div>
                ) : (
                  ''
                )}
                <div className="dados_resumo-frete">
                  {this.props.values.chosen_delivery_option !== null && this.props.values.delivery_options !== null ? (
                    <React.Fragment>
                      <p>
                        <strong> frete </strong> para&nbsp;
                        <a
                          className="link_cep"
                          href={this.props.values.step2_show || this.props.step3_show ? '#dados_entrega' : '#'}
                          onClick={() => {
                            if (this.props.values.step2_show || this.props.values.step3_show) {
                              this.props.setFieldValue('step1_show', false);
                              this.props.setFieldValue('step2_show', true);
                              this.props.setFieldValue('step3_show', false);
                              this.props.setFieldValue('show_cep', true);
                              this.props.setFieldValue('show_cep_result', true);
                              this.props.setFieldValue('show_ship_rate_result', true);
                            }
                          }}
                          style={{
                            cursor:
                              this.props.values.step2_show || this.props.values.step3_show ? 'pointer' : 'not-allowed'
                          }}
                        >
                          {this.props.values.shipping_info.address.zip_code.replace(/(\d{5})(\d{3})/, '$1-$2')}
                        </a>
                        <span>
                          {this.props.values.values?.delivery_options[0]?.estimate_business_days === 0 ? (
                            'chega hoje! :)'
                          ) : (
                            <>
                              entregue via&nbsp;
                              {this.props.values.chosen_delivery_option.id === 1 ||
                              this.props.values.chosen_delivery_option.id === 2
                                ? 'Correios'
                                : 'Transportadora'}
                              <i>
                                &nbsp;em até {this.props.values?.delivery_options[0]?.estimate_business_days} dias úteis
                              </i>
                            </>
                          )}
                        </span>
                      </p>
                      <p>
                        <strong>
                          {this.props.values.totals.shipping - this.props.values.totals.shipping_discount === 0
                            ? 'grátis'
                            : 'R$ ' +
                              (this.props.values.totals.shipping - this.props.values.totals.shipping_discount)
                                .toFixed(2)
                                .replace('.', ',')}
                        </strong>
                      </p>
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
                <div className="dados_resumo-total">
                  {this.props.values.chosen_delivery_option !== null ? (
                    <React.Fragment>
                      <p> total </p>
                      <p>
                        R$
                        {parseFloat(Math.round(this.props.values.totals.total * 100) / 100)
                          .toFixed(2)
                          .replace('.', ',')}
                        {this.props.values.installment_chosen !== '' && (
                          <span>em {this.props.values.installment_chosen}x no cartão </span>
                        )}
                        {this.props.values.payment_chosen === 'boleto' && <span> à vista no boleto </span>}
                      </p>
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment />
            )}
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </div>
    );
  }
}

export default SummaryPage;
