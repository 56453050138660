import checkPack from './checkPack';
import { getPhone } from '../utils/jsonGenerator';

function setFields(type, setFieldValue, result) {
  if (type === 'ship_calculator') {
    setFieldValue('id', result.id);
    setFieldValue('shipping_info.address', result?.shipping_info?.address);
    setFieldValue('shipping_info.person.phone', getPhone(result));
    setFieldValue('billing_info.person.phone', getPhone(result));
    if (result.delivery_options.length !== 0) {
      setFieldValue('delivery_options', result.delivery_options);
      setFieldValue('chosen_delivery_option', result.chosen_delivery_option);
    }
    setFieldValue('payment_options', result.payment_options);
    setFieldValue('show_ship_rate_result', true);
    setFieldValue('disabled', false);
    setFieldValue('totals', result.totals);
    setFieldValue('full_ship_chosen', result.delivery_options[0]);

    if (result.shipping_info.address.state_short == null || result.delivery_options.length === 0) {
      setFieldValue('fixFields', 'ops, verifique seu CEP');
    }

    result.shipping_info.address.neighborhood === null
      ? setFieldValue('show_neighborhood', true)
      : setFieldValue('show_neighborhood', false);

    result.shipping_info.address.street === null
      ? setFieldValue('show_street', true)
      : setFieldValue('show_street', false);
  } else if (type === 'product') {
    if (result.totals.total > 0) {
      setFieldValue('totals', result.totals);
    }
    setFieldValue('payment_options', result.payment_options);
  } else if (type === 'code' || type === 'save_customer' || type === 'save_address') {
    setFieldValue('awards', result.awards);
    setFieldValue('totals', result.totals);
    setFieldValue('delivery_options', result.delivery_options);
    setFieldValue('discount_code', result.discount_code);
    setFieldValue('show_cupom_field', false);
    setFieldValue('payment_options', result.payment_options);
    setFieldValue('full_ship_chosen', result.delivery_options[0]);
  } else if (type === 'getting_info_cookies') {
    if (result.customer) {
      setFieldValue('customer', result?.customer);
      setFieldValue('customer.phone', getPhone(result));
    }
    if (result.shipping_info && result.chosen_delivery_option) {
      setFieldValue('shipping_info', result.shipping_info);
      setFieldValue('delivery_options', result.delivery_options);
      setFieldValue('payment_options', result.payment_options);
      setFieldValue('show_cep_result', true);
      setFieldValue('show_ship_rate_result', true);
      setFieldValue('chosen_delivery_option', result.chosen_delivery_option);
      result.shipping_info.address.neighborhood === null
        ? setFieldValue('show_neighborhood', true)
        : setFieldValue('show_neighborhood', false);
      result.shipping_info.address.street === null
        ? setFieldValue('show_street', true)
        : setFieldValue('show_street', false);
    }
    if (result.discount_code) {
      setFieldValue('discount_code', result.discount_code);
      if (result.discount_code.valid === false) {
        setFieldValue('discount_code.code', '');
      }
      setFieldValue('show_cupom_field', false);
    }
    if (result.products.length > 0) {
      setFieldValue('products', result.products);
    } else {
      setFieldValue('isCartEmpty', true);
    }
    if (result.awards) setFieldValue('awards', result.awards);
    if (result.totals) setFieldValue('totals', result.totals);
    setFieldValue('id', result.id);
  } else if (type === 'getting_info_utm') {
    setFieldValue('trackings.utm_medium', result.medium);
    setFieldValue('trackings.utm_source', result.source);
    setFieldValue('trackings.utm_campaign', result.campaign);
    setFieldValue('trackings.utm_term', result.term);
    setFieldValue('trackings.utm_content', result.content);
  } else if (type === 'clean_address') {
    setFieldValue('show_cep', false);
    setFieldValue('shipping_info.address.city', '');
    setFieldValue('shipping_info.address.neighborhood', '');
    setFieldValue('shipping_info.address.street', '');
    setFieldValue('shipping_info.address.state', '');
    setFieldValue('shipping_info.address.state_short', '');
    setFieldValue('shipping_info.address.complement', '');
    setFieldValue('show_cep', false);
    setFieldValue('show_cep_result', true);
  } else if (type === 'actual_step') {
    if (result.values.infoCookie.customer) checkPack.checkStep(result, setFieldValue);
  } else if (type === 'step1_setters') {
    setFieldValue('shipping_info.person.phone', getPhone(result));
    setFieldValue(
      'shipping_info.person.full_name',
      result.customer.first_name.trim() + ' ' + result.customer.last_name.trim()
    );
    //    setFieldValue('card_name', result.customer.first_name.trim() + ' ' + result.customer.last_name.trim());
    setFieldValue(
      'billing_info.person.full_name',
      result.customer.first_name.trim() + ' ' + result.customer.last_name.trim()
    );
    setFieldValue('card_document', result.customer.document);
    setFieldValue('card_phone', getPhone(result));
    setFieldValue('billing_info.person.document', result.customer.document);
    setFieldValue('billing_info.person.phone', getPhone(result));
    setFieldValue('fixFields', '');
  }
}

export default setFields;
