/* eslint-disable no-use-before-define */
import axios from 'axios';
import mailcheck from 'mailcheck';

import cart from '../utils/cartPost';
import siteTracking from './siteTracking';
import validators from './validators';
import setFields from '../utils/setFields';
import orderProcess from '../utils/process';
import { getPhone } from '../utils/jsonGenerator';
import { BACKEND_URL } from '../api';
import { PaymentStatus } from '../utils/axiosRetry';

function startProgressBar(state, event, setFieldValue) {
  const progressBtn = event?.currentTarget;

  if (progressBtn) {
    progressBtn.classList.remove('active');
  }
  setFieldValue('progress', '0%');
  setFieldValue('paymentRequest', { status: PaymentStatus.started });

  if (progressBtn && !progressBtn.classList.contains('active')) {
    progressBtn.classList.add('active');
  }
  let progress = 1;
  var progressBar = setInterval(function () {
    if (progress < 100 && document.getElementsByClassName('progress').length > 0) {
      progress += 3;
      setFieldValue('progress', progress + '%');
    } else {
      clearInterval(progressBar);
    }
  }, 300);
}

const checkPack = {
  checkCustomer: function (state, setFieldValue) {
    //checking the object Errors (it includes every validation error)
    let dadoErrado = false;
    for (var property in state.errors.customer) {
      if (Object.prototype.hasOwnProperty.call(state?.errors?.customer, property)) {
        if (typeof state.errors.customer[property] !== 'undefined' && state.errors.customer[property] !== null) {
          dadoErrado = true;
        }
      }
    }

    axios
      .all([
        axios.get(`${BACKEND_URL}/validators/phone?phone=${state.values.customer.phone.replace(/\D/g, '')}`),
        axios.get(`${BACKEND_URL}/validators/email?email=${state.values.customer.email}`)
      ])
      .then(
        axios.spread((phoneResponse, emailResponse) => {
          if (
            !validators.validateEmail(state.values.customer.email) &&
            !validators.validateName(state.values.customer.first_name) &&
            !validators.validateLastName(state.values.customer.last_name) &&
            !validators.validateCPF(state.values.customer.document) &&
            !validators.validatePhone(state.values.customer.phone) &&
            phoneResponse.data.valid &&
            emailResponse.data.valid &&
            dadoErrado === false
          ) {
            setFieldValue('fixFields', '');
            setFieldValue('step1_show', false);
            setFieldValue('step2_show', true);
            setFieldValue('shipping_info.person.phone', getPhone(state.values));
            setFields('step1_setters', setFieldValue, state.values);
            cart.cartPost(state.values, 'save_customer', setFieldValue);
            siteTracking.saveUser();
          } else if (dadoErrado) {
            setFieldValue('fixFields', 'ops! revise os campos obrigatórios acima ;)');
          } else if (!phoneResponse.data.valid && !emailResponse.data.valid) {
            document.getElementById('customer--phone').style.border = '1px solid #C31E1E';
            document.getElementById('customer_email').style.border = '1px solid #C31E1E';
            setFieldValue('fixFields', 'ops! número de telefone e email parecem ser inválidos');
          } else if (!phoneResponse.data.valid) {
            document.getElementById('customer--phone').style.border = '1px solid #C31E1E';
            setFieldValue('fixFields', 'ops! esse número de telefone parece ser inválido');
          } else if (!emailResponse.data.valid) {
            document.getElementById('customer_email').style.border = '1px solid #C31E1E';
            setFieldValue('fixFields', 'ops! esse email parece ser inválido');
          } else {
            setFieldValue('fixFields', 'ops! revise os campos obrigatórios acima ;)');
          }
        })
      );
  },
  checkAddress: function (state, setFieldValue) {
    let dadoErrado = false;

    if (state.errors && state.errors.shipping_info) {
      for (let property in state.errors.shipping_info.address) {
        if (Object.prototype.hasOwnProperty.call(state?.errors?.shipping_info?.address, property)) {
          if (typeof state.errors.shipping_info.address[property] !== 'undefined') {
            dadoErrado = true;
          }
        }
      }

      for (let property in state.errors.shipping_info.person) {
        if (Object.prototype.hasOwnProperty.call(state?.errors?.shipping_info?.person, property)) {
          if (typeof state.errors.shipping_info.person[property] !== 'undefined') {
            dadoErrado = true;
          }
        }
      }
    }

    if (
      state.values.shipping_info?.person?.full_name !== '' &&
      state.values.shipping_info?.zip_code !== '' &&
      state.values.shipping_info?.address?.street !== null &&
      state.values.shipping_info?.address?.street.replace(/\s/g, '') !== '' &&
      !isNaN(state.values.shipping_info?.address?.street_number) &&
      state.values.shipping_info?.address?.street_number !== null &&
      state.values.shipping_info?.address?.neighborhood !== null &&
      state.values.shipping_info?.address?.neighborhood.replace(/\s/g, '') !== '' &&
      state.values.shipping_info?.address?.city !== null &&
      state.values.shipping_info?.address?.city.replace(/\s/g, '') !== '' &&
      state.values.shipping_info?.address?.state !== null &&
      state.values.shipping_info?.address?.state.replace(/\s/g, '') !== '' &&
      state.values.shipping_info?.person?.phone !== '' &&
      state.values.delivery_options !== null &&
      dadoErrado === false
    ) {
      setFieldValue('step1_show', false);
      setFieldValue('step2_show', false);
      setFieldValue('step3_show', true);
      setFieldValue('summaryVisible', true);
      setFieldValue('disableOrder', false);
      setFieldValue('payment_chosen', 'credit_card');
      setFieldValue('installment_chosen', '1');
      setFieldValue('fixFields', '');
      cart.cartPost(state.values, 'save_address', setFieldValue);
      siteTracking.saveAddress();
    } else if (dadoErrado) {
      setFieldValue('fixFields', 'ops! revise os campos obrigatórios acima ;)');
    } else {
      setFieldValue('fixFields', 'ops! revise os campos obrigatórios acima ;)');
    }
  },
  checkEmail: function (state, setFieldValue) {
    let domains = [
      'bol.com.br',
      'gmail.com',
      'hotmail.com',
      'hotmail.com.br',
      'live.com',
      'outlook.com',
      'uol.com.br',
      'yahoo.com',
      'yahoo.com.br'
    ];
    let secondLevelDomains = ['yahoo', 'live', 'gmail', 'mail', 'outlook'];
    let topLevelDomains = ['com', 'com.br', 'net', 'net.br', 'org', 'org.br'];
    mailcheck.run({
      email: state.values.customer.email,
      domains: domains,
      secondLevelDomains: secondLevelDomains,
      topLevelDomains: topLevelDomains,
      suggested: function (suggestion) {
        setFieldValue('email_suggestion', suggestion.full);
      },
      empty: function () {
        setFieldValue('email_suggestion', '');
      }
    });
  },
  checkPayment: function (state, setFieldValue, event) {
    if (
      !validators.validateCardNumber(state.card_number) &&
      !validators.validateFullName(state.card_name) &&
      state.card_expirationMonth !== '' &&
      state.card_expirationYear !== '' &&
      state.card_cvv.length >= 3 &&
      state.card_cvv.length <= 4 &&
      !validators.validateCPF(state.card_document) &&
      !validators.validateBirth(state.card_birthday) &&
      state.installment_chosen !== '' &&
      state.payment_chosen !== ''
    ) {
      setFieldValue('isLoadingForm', false);
      setFieldValue('fixFields', '');

      //let cart_data = cartCookies.getCart();

      siteTracking.checkoutFinish(state);
      siteTracking.sendVirtualPageView(state);
      orderProcess.card(state, setFieldValue);
      startProgressBar(state, event, setFieldValue);
    } else {
      setFieldValue('fixFields', 'ops! revise os campos obrigatórios ;)');
    }
  },
  checkBoleto: function (state, setFieldValue, event) {
    //let cart_data = cartCookies.getCart();
    setFieldValue('isLoadingForm', false);
    setFieldValue('fixFields', '');
    siteTracking.checkoutFinish(state);
    siteTracking.sendVirtualPageView(state);
    cart.cartPost(state, 'boleto', setFieldValue);
    startProgressBar(state, event, setFieldValue);
  },
  checkAwards: function (state, setFieldValue) {
    let awardsProducts = state.awards.products;
    let newAwardsProducts = [];
    for (let i = 0; i < awardsProducts.length; i++) {
      if (
        awardsProducts[i].valid_first_buy !== false &&
        awardsProducts[i].valid_pre_condition !== false &&
        awardsProducts[i].valid_customer_tag !== false
      ) {
        newAwardsProducts.push(awardsProducts[i]);
      }
    }
    state.awards.products = newAwardsProducts;
  },
  checkStock: function (state, setFieldValue) {
    let tmp_products = [];
    let has_out_of_stock_products = false;

    for (let i = 0; i < state.products.length; i++) {
      if (state.products[i].has_stock) {
        tmp_products.push(state.products[i]);
      } else {
        has_out_of_stock_products = true;
      }
    }
    if (has_out_of_stock_products) {
      setFieldValue('products', tmp_products);
    }
  },
  checkStep: function (state, setFieldValue) {
    if (
      !validators.validateEmail(state.values.infoCookie.customer.email) &&
      !validators.validateName(state.values.infoCookie.customer.first_name) &&
      !validators.validateLastName(state.values.infoCookie.customer.last_name) &&
      !validators.validateCPF(state.values.infoCookie.customer.document) &&
      !validators.validatePhone(state.values.infoCookie?.customer?.phone)
    ) {
      setFieldValue('step1_show', false);
      setFieldValue('step2_show', true);
      setFields('step1_setters', setFieldValue, state.values.infoCookie);

      if (
        state.values.infoCookie.shipping_info &&
        state.values.infoCookie.shipping_info.address.zip_code.length === 8
      ) {
        setFields('ship_calculator', setFieldValue, state.values.infoCookie);
        if (state.values.infoCookie.delivery_options.length !== 0) {
          setFieldValue('show_cep_result', true);
          setFieldValue('fixFields', '');

          if (
            !validators.validateStreetNum(state.values.infoCookie.shipping_info.address.street_number) &&
            !validators.validateStreet(state.values.infoCookie.shipping_info.address.street) &&
            !validators.validateComplement(state.values.infoCookie.shipping_info.address.neighborhood)
          ) {
            setFieldValue('step2_show', false);
            setFieldValue('step3_show', true);
          }
        }
      }
    }
    /*
    if (
      state.shipping_info &&
      state.shipping_info.zip_code.length === 8 &&
      !validators.validateStreetNum(
        state.shipping_info.address.street_number
      ) &&
      state.values.shipping_info.address.state.trim() !== "" &&
      !validators.validateComplement(state.shipping_info.address.street) &&
      !validators.validateComplement(
        state.shipping_info.address.neighborhood
      ) &&
      !validators.validateComplement(state.shipping_info.address.complement)
    ) {
    }*/
  }
};

export default checkPack;
