import JSEncrypt from 'node-jsencrypt';
import axios from 'axios';
import { MoipCreditCard } from 'moip-sdk-js';
import { captureException } from '@sentry/react';

import { successHandler, errorHandler } from '../utils/axiosRetry';
import jsonFactory from '../utils/jsonGenerator';
import { BACKEND_URL, PAYMENT_SERVICE_PUB_KEY } from '../api';

function encryptSallveHash(values) {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(PAYMENT_SERVICE_PUB_KEY);

  return encrypt.encrypt(
    JSON.stringify({
      cardNumber: values.card_number,
      cardCvv: values.card_cvv,
      cardDueDate: `${values.card_expirationMonth}/${values.card_expirationYear}`
    })
  );
}

const orderProcess = {
  card: function (values, setFieldValue) {
    const wirecardPubKey = `-----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAufPpqqOG7Y8XfKpHYaYi
    9BrzVk2dzjV9U6sH6spWn/w4ft322hXWE1hRjUvrzAcu3JTyjVoGLT9QxCIAKvZZ
    Wx+2UAdGOmpxRH/uXjl50wG7XBDdUR7OpGc4GraqUzpp3bJihcPORUW7VCI+3hJk
    M4fkV+hfR/YDxpo4uYqUYVS0guftDGSc4wi7xxpiEVPu9UuHW4sZr1hrmBJKitqQ
    VBHDtYi9PqaTn5IxmX2uAhloZcI2P4CvjJf5Zm4hwnIfz9oKoAzqE+ib9PvsC5rR
    OjLiUMqBghxdoU9tMozB9iNxZrHDc7ydRCm5c3cBGhv+Rb1tZDnPCULHSdXRfoRj
    /QIDAQAB
    -----END PUBLIC KEY-----`;

    let cardDataToSend = jsonFactory.jsonGenerator(values, 'card_data');

    axios
      .post(`${BACKEND_URL}/cart/`, cardDataToSend)
      .then((result) => {
        MoipCreditCard.setPubKey(wirecardPubKey)
          .setEncrypter(JSEncrypt, 'node')
          .setCreditCard({
            number: values.card_number,
            cvc: values.card_cvv,
            expirationMonth: values.card_expirationMonth,
            expirationYear: values.card_expirationYear
          })
          .hash()
          .then((wirecardHash) => {
            const sallveCCHash = encryptSallveHash(values);
            let dataToSend = jsonFactory.cardGenerator(values, wirecardHash, sallveCCHash);
            axios
              .post(`${BACKEND_URL}/order/sync`, dataToSend)
              .then((result) => {
                successHandler(result, setFieldValue);
              })
              .catch((result) => {
                errorHandler({ errors: result.response.data.errors, cart: values, setFieldValue: setFieldValue });
              });
          })
          .catch((error) => {
            captureException(error);
          });
      })
      .catch((error) => {
        captureException(error);
      });
  }
};

export default orderProcess;
